<template lang="pug">
#Particles(:style="{transform: `translate3d(${transform.x}px, ${transform.y}px, 0) scale(${transform.scale})`}")
</template>

<script>
import * as THREE from "three";
// import WebGLView from '@/assets/scripts/webgl/WebGLView';
// import GUIView from '@/assets/scripts/gui/GUIView';
import App from '@/assets/particles/App';
import {mapMutations} from 'vuex'
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
// import Stats from "three/examples/jsm/libs/stats.module";
// import { GUI } from "three/examples/jsm/libs/dat.gui.module";

import textToImage from "@/assets/js/textToImage"

export default {
  name: "Particles",
  props: {
    mode: {
      type: String,
      default: "image"
    },
    initImageUrl: {
      type: String,
      default: ""
    },
    imageUrl: {
      type: String,
      default: ""
    },
    transform: {
      type: Object,
      default: ()=>{
        return {
          x: 0,
          y: 0,
          scale: 1
        }
      }
    }
  },
  components: { },
  data() {
    return {
      app: null,
      lastImageUrl: "",
    }
  },
  methods:{
    ...mapMutations(['setCurrentEventId','deltaCurrentEventId']),
    next(){
      this.app.click()
    },
    setImage(imageUrl,delay=800){
      this.lastImageUrl=imageUrl
      this.app.setImage(imageUrl,delay)
    },
    hideParticles(){
      this.app.hideParticles()
    },

    reload(){
      this.app.setImage(this.lastImageUrl)
    }
  },
  mounted(){
    this.app  = new App("#Particles", this.initImageUrl);
    this.app.init();
    this.hideParticles()
  },
  beforeDestroy(){
    // distory app before unmounted
    this.hideParticles()
  },
  watch:{
    imageUrl(){
      setTimeout(()=>{
        if (this.app){
          this.setImage(this.imageUrl)
        }
      },500)
    }
  }
};

</script>
<style lang="sass">
#Particles 
  position: absolute
  width: 100%
  height: 100%
  left: 0
  top: 0
  transform-origin: center center
  // transform: scale(0.5)
  

</style>