export default {
  checkIfImageExist: imgName => {
    try {
      // let img = require(`/img/yearImages/${imgName}.png`)
      // console.log(img)
    } catch (error) {
      console.log(error);
    }
  },
  createImage: textContent => {
    textContent = textContent || "2021";
    const canvasWidth = 300;
    const canvasHeight = 200;

    let canvas = document.createElement("canvas");
    canvas.setAttribute("id", "canvasEl");
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    // document.body.appendChild(canvas)

    let ctx = canvas.getContext("2d");

    ctx.font = "600 60px Noto Sans";
    // ctx.font = "600 150px Noto Sans";
    // ctx.fillStyle = "#001234";

    ctx.lineWidth = 1;

    // 加上漸層讓粒子層次更明顯
    var gradient = ctx.createLinearGradient(0, 0, canvasWidth, canvasHeight);
    gradient.addColorStop(0.3, "rgb(200,200,30)");
    gradient.addColorStop(0.9, "rgb(30,180,180)");

    ctx.fillStyle = gradient;
    ctx.shadowBlur = 10;
    ctx.shadowColor = "rgb(50,50,50,200)";
    // ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    // ctx.fillStyle = '#FFF000'

    // ctx.strokeStyle = "#FFF"

    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.fillText(textContent, canvasWidth / 2, canvasHeight / 2);

    // let pngImage = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    // window.location.href=pngImage;

    return canvas.toDataURL("image/png");
  }
};
