<template lang="pug">
transition(name="fade")
  .fullpage(v-if="show")
    slot.front
    .closebg(@click="closeFullpage")
    .cross(@click="closeFullpage")
      i.fas.fa-times
    .decoration-1.position-absolute
    .decoration-2.position-absolute
    .decoration-3.position-absolute
    .decoration-4.position-absolute
    .decoration-5.position-absolute
    .decoration-6.position-absolute
    .decoration-7.position-absolute
    .container-fluid
      .row
        .video-content.col-12
          .left-block.position-relative.pr-5
            .tag-group.d-flex.align-items-center.my-2
              .date {{video.date.replace(/\//g,'-')}}
              //- button.tag #Tag
              //- button.tag #Tag
            h2.title.position-relative {{video.title}}
            article
              p {{video.content}}
        //- .presenter-block.col-3
            ul.d-flex.justify-content-center.align-item-center.flex-column.h-100.pl-5.ml-5.mt-3
              li.list-title.position-relative.pl-4.mb-2 INFORMATION
              li.list-item.position-relative 與談人：ＯＯＯ
              li.list-item.position-relative 講者：ＯＯＯ
              li.list-item.position-relative 與談人：ＯＯＯ
              li.list-item.position-relative 講者：ＯＯＯ
              li.list-item.position-relative 與談人：ＯＯＯ
              li.list-item.position-relative 講者：ＯＯＯ
</template>

<script>
import {mapMutations} from 'vuex'
export default {
  props: ["show", "video"],
  methods: {
    ...mapMutations(['toggleBgMusic']),
    closeFullpage() {
      this.$emit("closeFullpage");
      this.toggleBgMusic('on')
    }
  },
  mounted() {
    window.addEventListener("keydown", evt => {
      // console.log(evt.key)
      if (evt.key === "Escape") {
        this.closeFullpage();
        this.toggleBgMusic()
      }
    });
  }
};
</script>

<style lang="sass">
@import "@/assets/css/mixins"

.fullpage
  position: fixed
  width: 100%
  height: 100vh
  max-height: 100vh
  left: 0
  top: 0
  background-color: rgba(#0a0e1b, 0.95)
  z-index: 2000
  overflow-x: hidden
  overflow-y: auto
  padding: 6vw
  padding-top: 30px
  padding-bottom: 30px
  color: white
  overflow-y: scroll
  .front
    position: relative

  .decoration-1
    left: -10px
    top: 10%
    background-image: url("../assets/images/decoration/decoration_1.png")
    width: 68px
    height: 68px
    z-index: -1
  .decoration-2
    right: -34px
    bottom: 20%
    background-image: url("../assets/images/decoration/decoration_1.png")
    width: 68px
    height: 68px
    z-index: -1
  .decoration-3
    top: 36px
    left: 10%
    background-image: url("../assets/images/decoration/decoration_2.png")
    width: 41px
    height: 20px
    z-index: -1
  .decoration-4
    top: -38px
    left: 8%
    background-image: url("../assets/images/decoration/decoration_3.png")
    width: 12px
    height: 76px
    z-index: -1
  .decoration-5
    right: 20%
    bottom: 0
    background-image: url("../assets/images/decoration/decoration_4.png")
    width: 12px
    height: 29px
    z-index: -1
  .decoration-6
    right: 16%
    bottom: -5%
    background-image: url("../assets/images/decoration/decoration_2.png")
    width: 41px
    height: 20px
    z-index: -1
  .decoration-7
    right: 14%
    bottom: -15%
    background-image: url("../assets/images/decoration/decoration_5.png")
    width: 12px
    height: 76px
    z-index: -1

  .closebg
    position: absolute
    z-index: -1
    width: 100%
    height: 100%
    left: 0
    top: 0

  .cross
    cursor: pointer
    transform: scale(1.1)
    font-size: 35px
    position: fixed
    right: 3vw
    top: 1.5vw
    color: white
    transition: 0.5s
    &:hover
      transform: scale(1.2)

  .video-content
    .left-block
      &:after
        position: absolute
        // content: ""
        right: 0
        top: 0
        height: 100%
        transform: translateY(10%)
        width: 1px
        background-color: #ffffff
      .date
        font-family: Roboto, sans-seriff
        font-size: 18px
        line-height: 21px
        color: #ffffff
        opacity: 0.7
        margin-right: 8px
      .tag
        background-color: inherit
        border: none
        color: #ffffff
        opacity: 0.7
        height: 21px
        line-height: 21px
        padding: 0
        margin: 0 8px
      .title
        font-size: 48px
        font-weight: 700
        font-family: Noto Sans TC, sans-serif
        line-height: 70px
        &:before
          content: ""
          position: absolute
          width: 11px
          height: 11px
          background-color: #ffffff
          left: -12px
          bottom: 0
        &:after
          content: ""
          position: absolute
          width: 1px
          height: 200px
          background-color: #ffffff
          left: -80px
          bottom: -170px
          transform: rotate(45deg)
      article
        color: #ffffff
        opacity: 0.7
  .presenter-block
    .list-title
      font-size: 24px
      font-weight: 600
      font-family: Noto Sans, sans-serif
      line-height: 33px
      color: #ffffff
      &:before
        content: ""
        position: absolute
        width: 16px
        height: 16px
        background-color: #FF365F
        border-radius: 50%
        left: 0
        top: 50%
        transform: translateY(-50%)
    .list-item
      font-family: Noto Sans TC, sans-serif
      font-size: 18px
      line-height: 37px
      color: #ffffff
      opacity: 0.8
</style>
