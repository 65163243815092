export default {
    lang: '中',
    backToHome: 'Home',
    home: {
        title: "",
        scenes: {
            edu: {
                title: "Education",
                items: {
                    "edu_2": {
                        "title_sub": "體溫感測系統",
                        "title_main": "A Temperature Sensing System",
                        "desc": "Set up body temperature sensing at the school gate. Students use digital student ID cards to perform non-contact body temperature sensing after RFID. If the body temperature is too high, follow-up anti-epidemic measures will be taken.",
                        "link": ""
                    },
                    "edu_1": {
                        "title_sub": "臺北酷課網路學校防疫不停學",
                        "title_main": "Taipei Cool Class Online School Stops Epidemic Prevention",
                        "desc": "Taipei Cool Class Online School breaks down the barriers of time and space with learners as the center, providing simultaneous and asynchronous long-distance online teaching courses for various subjects, providing digital learning courses for students during the outbreak of the epidemic, and taking into account the students' right to learn. The quality of teaching and student learning status, and the creation of cross-county, city, and cross-school cooperation resource sharing, the practice of the non-stop epidemic prevention of Taipei Cool Class Online School.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=142"
                    }
                }
            },
            health: {
                title: "Health",
                items: {
                    "health_3": {
                        "title_sub": "智慧客服小照",
                        "title_main": "Official Line Account-Xiao Zhao to Learn About Long-term Care",
                        "desc": "Xiǎo Zhào, an official AI in line@, can be instantly responsive to citizens about long-term care resources. Citizens only need to apply ‘On-line Application’ first, then simply clicking ‘I want to know’ icon, and Xiǎo Zhào may either offer condensed reply or referring users to withdraw needed resources. In addition, through line@, governors may actively push messages to line members, including promoting long-term care information and raising citizens’ recognition to policies. This dual communication empower Taipei Health Dept. also citizens being effectively acknowledged each other.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=129"
                    },
                    "health_4": {
                        "title_sub": "AI智能化360儀表板",
                        "title_main": "hiAI-360 Dashboard",
                        "desc": "360-degree Dashboard of AI-based Psychosis Care: It performs personalized risk analysis form a 'people-centered' perspective. Combining with residents' health data in the cloud and automated comprehensive analysis help doctors and nurses work effectively.<br>9 Body Constitution Dashboard of Patient-centered Health Exam of TCM: It help physicians to prepare personalized physical conditioning prescriptions according to physical characteristics. In addition, it applies Ethnic Chinese Intelligent Engine to combining with traditional Chinese medicine examination and Western medicine clinical comprehensive analysis of empirical medicine to improve the overall self-health management ability of patients.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=128",
                        "video": "https://www.youtube.com/embed/HLltl6TGTAE"
                    },
                    "health_1": {
                        "title_sub": "臺北市預防接種資訊系統",
                        "title_main": "Taipei Immunization Information System",
                        "desc": "To provide citizens the easy way catching the information referring to Taipei immunization policy, Health Department of Taipei City Gov. developed Taipei Immunization Information System in 2017. This system is to bridge a platform for citizen to simultaneously access, through electronic devices such as computers or mobile phones, subsidy requirements, maps of Taipei City-appointed hospitals, and ideas of vaccine and related news included.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=136"
                    },
                    "health_2": {
                        "title_sub": "區塊鏈於醫療照護之應用",
                        "title_main": "Blockchain Application in Health Care",
                        "desc": "Integrated Information Platform of Healthcare personnel will be able to examine people’s healthcare information, as authorized, from all collaborating institutes via the Healthcare Information Authorization App, which combines the smartphone with the smart contracts of blockchain technology. Healthcare personnel will finally surmount the difficulty of distance to obtain the healthcare information of the recipient of care through the Integrated Information Platform of Healthcare. This platform has not only changed the traditional service model of the care service personnel on the frontlines, but also expanded the scope of the service and increased the quality of the care itself. In addition, This approach not only empowers patients with direct control of when and where to sign, but also ensuring the security and correctness of digital signature through the characteristics that the blockchain data cannot be tampered with.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=134",
                        "video": "https://www.youtube.com/embed/Pt-0udUgW-Q"
                    }
                }
            },
            traffic: {
                title: "Traffic",
                items: {
                    "traffic_1": {
                        "title_sub": "GoShare 移動共享服務",
                        "title_main": "GoShare Mobility Sharing Service",
                        "desc": "Motorcycle-Sharing gradually changed the citizens’ habit of using cars(buying cars)  through different people using one scooter to reduce theownership of private vehicles, reduce the demand for parking in urban areas,and also provide the first and last-mile connecting transport for the public.<br> GoShare® is the world’s first end-to-end mobility sharing platform powered by the Gogoro® ecosystem. Through the GoShare App, consumers are able to find, reserve, and ride a Gogoro Smartscooter® or a Powered by Gogoro NetworkTM Scooter any time they need it, and enjoy an easy and fun riding experience that best fits daily mobility needs.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=150"
                    },
                    "traffic_2": {
                        "title_sub": "自駕巴士創新實驗計畫",
                        "title_main": "Autonomous Driving Bus Innovative Experiment Plan",
                        "desc": "Taipei City Government cooperated with Turing Drive Co. Ltd. to initiate a midnight self-driving bus test on the bus lane of Xinyi Road. From May, this test will verify the various scenarios that may face in the future. According to the results, the test will be gradually to carry passengers, and the public will experience the test ride as soon as September this year. Turing Drive Co. Ltd. is a new domestic team that specializes in the development of autonomous driving systems, combining deep learning, sensor fusion, parallel computing, and vehicle dynamic control technologies.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=155"
                    },
                    "traffic_4": {
                        "title_sub": "智慧車聯網及車隊管理系統",
                        "title_main": "Smart IoT and Fleet Management System",
                        "desc": "Motorcycle-Sharing gradually changed the citizens’ habit of using cars(buying cars)  through different people using one scooter to reduce the ownership of private vehicles, reduce the demand for parking in urban areas,and also provide the first and last-mile connecting transport for the public.<br>Aside from the vehicle sharing service, WeMo is capable of connecting diverse transportation management tools with its proprietary IoT device. For example, battery charging stations, cars, motorcycles, and bicycles can be remotely controlled and managed. WeMo IoT technology can widely enhance the efficiency and momentum of intelligent transportation in the city by connecting applications such as rental management, multi-payment gateways, fleet management, battery management, maintenance, and customer management.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=151"
                    },
                    "traffic_5": {
                        "title_sub": "智慧停車",
                        "title_main": "Smart Parking",
                        "desc": "In order to provide people with full day real-time parking information and smart payment services, we plan smart parking spaces to deploy sensing facilities to detect when vehicles enter and leave, if you receive a smart parking payment notice, you can use your mobile phone to scan the QR-CODE on the smart parking payment notice to check parking information, pay with smart payment, and enjoy a 10-minute free parking. We provide 24-hour real-time parking information, the【iTaipeiParking】App can query real-time parking information and it will save 5 to 10 minutes to find a parking space.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=156"
                    },
                    "traffic_3": {
                        "title_sub": "YouBike公共自行車展示",
                        "title_main": "Display of Bike Sharing System-YouBike",
                        "desc": "YouBike uses an electronic unmanned automated management system to provide “ A Leases and B Returns” bike rental service. We hope that bikes will be chosen as the first and last-mile public transit vehicle and more citizens will be glad to use the mass transit system and meanwhile, environmental protection and energy conservation will be achieved and a new commuting culture will emerge.<br>YouBike Features<br>1. Subsystem of Public Transportation<br>2. Easy Access Convenience<br>3. Robust Service<br>4. Comfortable & Safe Bike",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=153"
                    }
                }
            },
            security: {
                title: "Security",
                items: {
                    "security_2": {
                        "title_sub": "Smart Policing-「北市智慧警勤APP」2.0",
                        "title_main": "Smart Policing-TCPD Smart App 2.0",
                        "desc": "In order to facilitate a less burdensome working environment for rank-and-file police officers, and realize Mayor Ko’s vision of Intelligent Policing, the TCPD has been the first to develop the “TCPD Work Smart Platform”, including features such as the “Digitalized Officer Duty Log”, “Digitalized Officer Patrol System”, “Digital Stop Check System” the “Traffic Violations Reporting System” and the “Real-Time Footage Sharing System”. Along with the implementation of hardware such as the handwriting tablet, the M-Police mobile mini-computer and Bluetooth photocopier, the digitalization campaign has greatly enhanced officers’ working environment and significantly boosted overall policing efficiency.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=145"
                    },
                    "security_1": {
                        "title_sub": "消防智慧物聯網",
                        "title_main": "AIoT-based Smart Fire Alarm System",
                        "desc": "By applying AIoT technology in fire safety equipments , AIoT-based Smart Fire Alarm System provides solutions as follows:\n 1.If fire safety equipments and fire escape equipments ruined due to human behaviors, AIoT-based Smart Fire Alarm System will supervise the situation timely,while automatically telling the abnormal case,then it notifies fire safety manager soon.\n 2. In case of fire, using the former technology, AIoT-based Smart Fire Alarm System will notify fire safety manager instantly, while automatically notifying 119 ,transmitting real-time fire information to reduce the time for dispatching fire fighters and engines.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=143"
                    }
                }
            },
            architecture: {
                title: "Architecture",
                items: {
                    "architecture_4": {
                        "title_sub": "創新指標",
                        "title_main": "Innovation Index",
                        "desc": "This project is the only design that wins all three awards which are Intelligent Building Gold Grade, Green Building Gold Grade and TIBA Award Gold Grade. These awards give the design concept of this project a really good affirmation. \n The followings are the innovation indicators of this project.\n 1.Telecommunication format consistency.\n 2.Importing BIM System.\n 3.IP Structure planning.",
                        "link": ""
                    },
                    "architecture_3": {
                        "title_sub": "預期潛在效益",
                        "title_main": "the expected potential efficiency",
                        "desc": "This project expected to enhance the user working efficiency and lower the building operational cost by importing all kinds of intelligent system. The expected potential efficiency can be divided into two aspects.\n 1. Reduce Cost\n •Lighting system power saving up to 30%\n •Elevator system power saving up to 30 %\n •Renewable energy lower yearly energy consumption at 272,457.48kWh\n •Maintenance cost saving up to 20%\n 2. Increase Gain\n •Increase rent income\n •Increase operational efficiency at 24.6%",
                        "link": ""
                    },
                    "architecture_1": {
                        "title_sub": "廣慈智慧社宅整合管理平台",
                        "title_main": "Guanchi Intelligent Social Residence Integration Management Platform",
                        "desc": "This project leads by Taipei City Government is to rebuild the entire Guanchi charity park. Due to the large land coverage and contains several different buildings, we established a Central Monitoring Center in Building D by using the IWA Smart Building Integration Management Platform. With IWA SBIMP, we can control and manage all facilities inside all buildings, BA systems, provides user friendly interfaces and integrated with BIM system to lower the cost on human resource and power consumption.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=140"
                    },
                    "architecture_2": {
                        "title_sub": "無人機自動駕駛巡邏技術",
                        "title_main": "Drone Patrol Autopilot Technology",
                        "desc": "Drone Patrol Autopilot Technology. Create A New Application For Smart Surveillance of Community Security.",
                        "link": ""
                    }
                }
            },
            gov: {
                title: "Government",
                items: {
                    "gov_2": {
                        "title_sub": "帳單",
                        "title_main": "Bills",
                        "desc": "Avoid Lines When Paying Bills: You can look up bills such as water fee, parking fee, municipal taxes, and tuition via the TaipeiCard app. Through the online payment mechanism, you can pay your bills without even leaving your door. After paying them, you can obtain the records from TaipeiCard app for bookkeeping purposes. <br><br>Customizing Frequently-used Information: For handy information such as your water bill account and vehicle registration plate number, you can store them in your TaipeiCard app. Input them ahead of time to save your trouble from looking them up each time you need to pay bills.",
                        "link": ""
                    },
                    "gov_3": {
                        "title_sub": "台北通",
                        "title_main": "TAIPEI PASS",
                        "desc": "Taipei City Government combines personal identity functions with a number of city services to create TAIPEI PASS. The service is coming soon. <br><br>In addition to opening up a new channel for accessing services, TAIPEI PASS is also a digital identity mechanism designed specifically for Taipei, connecting the government with every single resident and bringing city services close to the daily life of citizens to realize a sense of happiness for its people through Smart Taipei. As a single portal for those with membership, TAIPEI PASS integrates resources and city services which affect day-to-day life of citizens to construct a verification mechanism for digital citizen ID. It also links various online and real-world services to provide citizens with convenient identity and payment tools they can use for various daily life applications. <br><br>By signing up for TAIPEI PASS membership, you will have access to services provided by the city government. TAIPEI PASS is the key for citizens to unlock the gate of city public services.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=224"
                    },
                    "gov_1": {
                        "title_sub": "服務",
                        "title_main": "Services",
                        "desc": "No Need for Memorizing Multiple Passwords: You can use your TaipeiCard account to log into multiple online platforms and quickly acquire authorization across these platforms and easily access city services.",
                        "link": ""
                    },
                    "gov_4": {
                        "title_sub": "卡證",
                        "title_main": "Cards",
                        "desc": "Three Step EasyCard Binding Process: All it takes is three steps to bind your EasyCard, allowing you to look up your electronic uniform-invoices and shopping history. After you bind your EasyCard, I could be used as TaipeiCard and enjoy access to all privileged services.<br><br>Bring Your Virtual Card with You: Do you find it troublesome to bring a stack of cards with you because you don’t want to miss out on any of the membership privileges or discounts? Now you can bring your card with you virtually with the TaipeiCard app! Enjoy access to your card information and privileged services at your fingertip!<br><br>Note: A portion of membership services and discounts still require a physical card.",
                        "link": ""
                    }
                }
            },
            eco: {
                title: "Economics",
                items: {
                    "eco_1": {
                        "title_sub": "智慧支付TPQR共通碼",
                        "title_main": "Taipei QR",
                        "desc": "The Taipei QR (TPQR) Service provides the public with diversified electronic payment options. In addition to paying Taipei City’s public service fees and fines, the service seeks to connect with Taipei Zoo, off-road smart parking facilities, commercial districts, markets, and city government activities involving more than 1000 participants, as well as other cross-municipality and private sector use scenarios. It also looks forward to bring in more third-party payment companies to create an even more open and diversified e-payment ecosystem to jointly promote smart applications for urban settings.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=185"
                    },
                    "eco_3": {
                        "title_sub": "悠遊付功能「商圈微店公有市場」",
                        "title_main": "Shopping Districts, Microstores, and Public Markets",
                        "desc": "In line with the Taipei City Government's vision to promote a cashless city, EasyWallet actively enters various shopping districts, micro-stores, and public markets to implement a cashless smart life. Part of the city's efforts to build a smart city is to promote cashless traditional markets. EasyWallet's entry in well-known public markets (for example, Nanmen Market) relieves people from paying with greasy bills and coins and helps control the pandemic in many ways.",
                        "link": ""
                    },
                    "eco_5": {
                        "title_sub": "悠遊付功能「未成年使用悠遊付」",
                        "title_main": "EasyCard for Minors",
                        "desc": "Minors under the age of 20 can register for EasyWallet. With the consent of 2 legal guardians, they can download and register on EasyWallet. Parents can use the EasyWallet money transfer function to give pocket money to their children, which is not only free of service charge but also provides them with access to money management and achieves convenient cashless lifestyle.",
                        "link": ""
                    },
                    "eco_2": {
                        "title_sub": "悠遊付功能「嗶乘車」",
                        "title_main": "Beep Transportation",
                        "desc": "Beep Transportation is an exclusive function introduced by EasyCard, allowing millions of commuters to access the MRT or automatically add value to their cards through mobile phones. After completing the registration on the Beep Transportation system, with a single beep of your mobile phone, you can freely access public transportation, including Taipei MRT, Taipei eBus and YouBike.",
                        "link": ""
                    },
                    "eco_4": {
                        "title_sub": "悠遊付App",
                        "title_main": "Easy Wallet",
                        "desc": "EasyCard has been the best transportation and spending companion in Taiwan. After 20 years of development in cashless payment, a new electronic payment system, EasyWallet, was introduced to bring the best electronic wallet using experience for consumers and businesses, and achieve the vision of a cashless lifestyle! The advantages of EasyWallet include beep transportation, EasyCard management, smart payment Integration and EasyCard for minors.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=141"
                    }
                }
            },
            env: {
                title: "Environment",
                items: {
                    "env_3": {
                        "title_sub": "智慧水情展示系統",
                        "title_main": "Taipei Smart Flood Information and Alerting System",
                        "desc": "Taipei Smart Flood Information and Alerting System is like a cloud flood information center in Taipei . By IOT Network , it integrates more than 500 different sensors(rainfall、river、sewer、pump station) and combines the latest weather observations(satellite Images、radar Images、tidal forecast).\n We use this system to get water level and rainfall automatic alert instead of monitoring massive datas of sensors by manpower. We can let all staffs to remind the situation and react more quickly. We also provide customized user interface suitable for different units and needs.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=146"
                    },
                    "env_2": {
                        "title_sub": "道路管線智慧化管理",
                        "title_main": "Road Pipeline by Smart Management",
                        "desc": "In order to resolve the problem of constantly digging roads that affect the road quality, Taipei City Government will establish \"'Taipei City Road and Pipeline Resource Center\" (shortened as \"Road and Pipeline Resource Center\"). Its priorities are to maintain and upgrade the roads, discard or replace pipelines, organize overhead cables, and integrate all designs that Taipei City Government is pushing forward and household pipelines for new housing plans. The scheduling and management of road repair will be integrated in order to avoid each pipeline organization from neglecting lateral communication, causing construction works to clash and affect traffic. There will also be severe limits placed on night construction in order to avoid affecting the tranquility of households.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=147"
                    },
                    "env_1": {
                        "title_sub": "邊坡智慧監測與管理",
                        "title_main": "The Digital Mornitoring and Management in Slopeland",
                        "desc": "The automatic monitoring systems for the hillsides in Taipei city are established to collect all the important data about the man-made slopes, landslide-landslip geologically sensitive areas and real-time hydrological condition.All the monitoring data can be immediately transmitted to the database and visualized on platforms.",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=148"
                    }
                }
            }
        }

    },
    about: {
        title: "About Us",
        desc: 'Taipei is a dynamic city: it has developed a vision of quality urban living, and defined its urban regeneration programs with this vision in mind. To continue building Taipei City to be a livable city, Taipei City Government declared "Open Government", "Citizen Participation", and "Public-Private Partnership" as its core visions in developing a smart solution-based ecosystem. In the meantime, Taipei City also adopts a sandbox approach – which allows room for failures – to encourage innovators to utilize Taipei City as their demonstration site, helping them to speed up the development of various solutions and providing citizens with excellent living environment.',
        brochure: "Smart Taipei brochure",
        brochure_url: "https://drive.google.com/file/d/14UYCuGkfk25rkOlEUat99r5qIbZMR9qw/view",
        video: "https://www.youtube.com/embed/wPiSqP1o2hs"
    },
    explore: {
        title: "Discover Taipei",
        intro: "Taipei is a metropolitan city that features in-depth history and cultures where the fast-pace and prosperity seem evident. However, the slow-paced aspect of the city is worth noticing as well.<br><br>Let’s examine the beauty of Taipei with the help of technology.",
        sights: {
            songyan: {
                name: "Songshan Cultural and Creative Park",
                name_en: "松山文創園區",
                desc: "In 2011, the Songshan Cultural and Creative Park was positioned as the “Creative Hub of Taipei”, with the objective of nurturing creative talents and energy. The Park is not designed with a commercial focus, but rather, its mission is to kindle creativity and innovation and to be in synch with the interdisciplinary developmental trend observed in today’s industries. The Songshan Cultural and Creative Park is not just a platform for showcasing creativity and innovation. It strives to become a hub for inspiring and nurturing the spirits of creativity, and with the five key strategies of “Creative Lab”, “Creative Union”, “Creative School”, “Creative Showcase” and “Creative Hub”, the objective is for the Park to achieve the goal of becoming Taipei’s creative hub."
            },
            beimen: {
                name: "North Gate (Beimen)",
                name_en: "北門",
                desc: "There are five historic city gates in Taipei: East Gate (Jingfu Gate), West Gate (Baocheng Gate), South Gate (Lizheng Gate), Lesser South Gate (Chongxi Gate) and North Gate (Cheng’en Gate). They were built by Liu Ming-chuan during the reign of Emperor Guangxu in the Qing Dynasty as a measure to expedite urban development by encouraging businessmen to invest in or build houses/streets in Taipei City.<br><br>In light of the emerging trend of cultural heritage preservation in recent years, the city authorities decided to abandon a demolition plan of the historic North Gate. Of the five ancient city gates, the North Gate is the only one that remains closest to its original form since the Qing Dynasty, as well as being one of the most valuable state-designated historic sites in Taipei."
            },
            honglou: {
                name: "Ximen",
                name_en: "西門",
                desc: "Looking for Taipei street fashion? Let’s go to Ximending! This long-lasting shopping district is young people’s favorite place for digging treasures: From delicacies, clothing to daily necessities, you can find various merchandise here with relatively cheaper prices. You will also see the street culture of Taipei, such as graffiti and street dancing. Ximen is definitely one of the representative areas of Taipei."
            },
            longshan: {
                name: "Lungshan Temple",
                name_en: "龍山寺",
                desc: "Wanhua is Taipei’s earliest developed area. Although not as flourishing as it was before, the neighborhood has preserved the “authentic culture” of Taipei. Its faith center, Longshan Temple, is known for its efficacious fulfillment of wishes and exquisite temple architecture – elements which attract many foreign tourists!<br><br>Around the Temple, you will also find many traditional delicacies and old stores. We welcome you to experience another facet of Taipei!"
            },
            moma: {
                name: "Zhongshan",
                name_en: "中山",
                desc: "Zhongshan Metro Mall and cluster of cultural creativity shops at nearby MRT Zhongshan Station give this area an artistic and cultural atmosphere. Surrounded by greenery of XinZhongShan Linear Park and the highly distinctive shops lining along Chifeng Street make the area a practically a place of pilgrimage for photography fans. Located near MRT Zhongshan Station, the SPOT-Taipei Film House was formerly the American ambassador's residence, but has now been converted into a multipurpose venue focusing on film culture, and containing a coffee shop and performing area. Tsai Jui-Yueh Dance Research Institute offers exhibition spaces and a coffee shop, which makes it a popular attraction for local hipsters. When you take a walk through this area, you will find surprises around every corner."
            },
            101: {
                name: "Taipei 101",
                name_en: "台北101",
                desc: "The prime area of Taipei is definitely Xinyi District. Known as the prime business district of Taiwan, Xinyi District features towering buildings and international business people travelling in and out. It is also a shopping area on the cutting edge of trends: Taipei 101, an important landmark of Taiwan; department stores, showcasing the latest global fashions; and the art market of Four Four South Village, which is renovated from an old military dependents' village and offers DIY activities from time to time!"
            },
            dadaocheng: {
                name: "Dadaocheng",
                name_en: "大稻埕",
                desc: "Where will you find old Taipei? Dadaocheng is a place you must not miss! Formerly an important and major trading port, it is still the top choice for local people to buy dried foods and raw materials today. This area also preserved a number of century-old buildings, which have been renovated for lease. Today, Dadaocheng has become an emerging cultural and creative incubation center of Taipei! Explore the old streets of Dadaocheng to experience the surprising collision of new and old cultures."
            }
        }
    },
    prevent: {
        "title": "Technology Against COVID-19",
        "subtitle": "科技防疫",
        "prevent_1": {
            "title": "Rationing Mask Vending Solution",
            "desc": "The rationing vending solution could be understood as traditional vending machine or POS with a rationing scheme add-on. There are at least three advantages to the solution: First, it can be easily upgraded, which is much faster than producing brand-new machines. Second, it can be easily repurposed, since the rationing scheme can be removed to allow the device to sell drinks again after the epidemic. Third, the use of e-payment and card payment minimizes the chance of vendors coming into contact with potential patients and coins.",
            "link": "https://smartcityonline.org.tw/scheme.php?id=137"
        },
        "prevent_2": {
            "title": "International Partnership Against COVID-19",
            "desc": "COVID-19 has become a global pandemic. Taiwan’s outstanding performance in controlling its COVID-19 epidemic has attracted the attention of and sparked interests in the international community to learn from Taiwan’s epidemic-prevention measures. Accordingly, Taipei City shares its innovative COVID-19-prevention experience and measures to cities worldwide via methods such as online platforms and meetings, facilitating and deepening exchanges between cities worldwide and making a difference in the world.",
            "link": "https://smartcityonline.org.tw/scheme.php?id=198"
        },
        "prevent_3": {
            "title": "Remote Working and Video Conferencing Solution",
            "desc": "The establishment of the Taipei City Government Remote Work Service Web is important as a balance between pandemic prevention measure and work as normal. The city government carried out the integration of resources and announcement of guidelines regarding remote working. It also ensures that in the event where workers are forced to telework, they can employ their own ITC devices via installed website extensions to fulfil the requirements for accessing the government information systems. In the case where calling a meeting is necessary, the city government provides a video conferencing system to ensure no interruption to administrative work during the pandemic period.",
            "link": "https://smartcityonline.org.tw/scheme.php?id=172"
        },
        "prevent_4": {
            "title": "COVID 19 Data Integration Dashboard",
            "desc": "In light of the COVID-19 pandemic, the Department of Information Technology (DOIT) developed the COVID-19 Data Integration Dashboard which gathers pandemic-related information from both Taiwan and abroad as references for city officials and the emergency response task force. The data collected include details such as the National Health Insurance Administration’s pharmacy face mask inventory, number of face mask sold via the face mask vending machines, number of users utilizing public venues, gauge of pandemic-related topics on the Internet, and more. The main data displayed here emphasizes comprehensive, cross-department matters and a viable solution to resolving the problem of data scattered across different agencies. The materials are presented in a fashion reflecting geospatial elements, ensuring that officials can grasp all necessary pandemic updates and current status of all administrative districts for decision-making references.",
            "link": "https://smartcityonline.org.tw/scheme.php?id=175"
        },
        "prevent_5": {
            "title": "Real Name Registration System for Venues and Facilities",
            "desc": "In compliance with CDC guidelines, keeping track of visitors in preparation for potential epidemic investigations while continuing the reopening of venues is an issue that needs to be addressed. DOIT developed the myCode application to provide public venues with a tool for real name registration in an efficient manner – by scanning ID card barcode. As a smartphone app, the program can be rapidly disseminated. Another benefit is that venues do not have to invest in software or equipment to utilize the app. All data collected by the app are encrypted and stored by DOIT, providing top security for personal information.",
            "link": "https://smartcityonline.org.tw/scheme.php?id=225"
        }
    },
    events: {
        date_dsc: "Date",
        location_dsc: "Venue",
        mode: "Mode",
        events: [{
                "year": 2020,
                "month": 8,
                "date": 13,
                "date_text": "2020/08/13(Thu) 17:00 ~ 18:40 (GMT+8)",
                "desc": "Mayors’ Summit Online",
                "location": "Smart City Summit & Expo YouTube Channel",
                "mode": "Broadcasting, Full English",
                "link": "https://smartcityonline.org.tw/forum_detail.php?id=8f14e45fceea167a5a36dedd4bea2543"
            },
            {
                "year": 2020,
                "month": 8,
                "date": 18,
                "date_text": "2020/08/18(Tue) 07:00 ~ 08:40 (GMT+8)",
                "desc": "Mayors’ Summit Online",
                "location": "Smart City Summit & Expo YouTube Channel",
                "mode": "Broadcasting, Full English",
                "link": "https://smartcityonline.org.tw/forum_detail.php?id=aab3238922bcc25a6f606eb525ffdc56"
            },
            {
                "year": 2020,
                "month": 8,
                "date": 19,
                "date_text": "2020/08/19 (Wed) 17:00 ~ 18:40 (GMT+8)",
                "desc": "Mayors’ Summit Online",
                "location": "Smart City Summit & Expo YouTube Channel",
                "mode": "roadcasting, Full English",
                "link": "https://smartcityonline.org.tw/forum_detail.php?id=9bf31c7ff062936a96d3c8bd1f8f2ff3"
            },
            {
                "year": 2020,
                "month": 9,
                "date": 1,
                "date_text": "2020/09/01(Tue) ",
                "desc": "Collaboration Seminar for Smart Cities of Northern Taiwan Development Commission",
                "location": "",
                "mode": "",
                "link": ""
            },
            {
                "year": 2020,
                "month": 9,
                "date": 2,
                "date_text": "2020/09/02(Wed) ",
                "desc": "Smart City Online Opening & Awards Ceremony",
                "location": "",
                "mode": "",
                "link": "https://smartcityonline.org.tw/"
            },
            {
                "year": 2020,
                "month": 9,
                "date": 8,
                "date_text": "2020/09/08(Tue)",
                "desc": "Digital Governance Forum in Smart City Taipei",
                "location": "",
                "mode": "",
                "link": ""
            },
            {
                "year": 2020,
                "month": 9,
                "date": null,
                "date_text": "September",
                "desc": "GO SMART Online Forum",
                "location": "",
                "mode": "",
                "link": "https://www.citiesgosmart.org/"
            },
            {
                "year": 2020,
                "month": 9,
                "date": 22,
                "date_text": "2020/09/22(Tue) 17:00 ~ 18:40",
                "desc": "CTalk Healthcare",
                "location": "Smart City Summit & Expo YouTube Channel",
                "mode": "Broadcasting, Full English",
                "link": "https://smartcityonline.org.tw/forum_detail.php?id=70efdf2ec9b086079795c442636b55fb"
            },
            {
                "year": 2020,
                "month": 9,
                "date": 23,
                "date_text": "2020/09/23(Wed) 17:30 ~ 18:40",
                "desc": "CTalk Innovation",
                "location": "Smart City Summit & Expo YouTube Channel",
                "mode": "Broadcasting, Full English",
                "link": "https://smartcityonline.org.tw/forum_detail.php?id=6f4922f45568161a8cdf4ad2299f6d23"
            },
            {
                "year": 2020,
                "month": 9,
                "date": 24,
                "date_text": "2020/09/23(Thu) 17:30 ~ 18:40",
                "desc": "CTalk Municipal",
                "location": "Smart City Summit & Expo YouTube Channel",
                "mode": "Broadcasting, Full English",
                "link": "https://smartcityonline.org.tw/forum_detail.php?id=1f0e3dad99908345f7439f8ffabdffc4"
            },
            {
                "year": 2020,
                "month": 12,
                "date": 2,
                "date_text": "2020/12/02(Wed) ~ 12/06(Sun)",
                "desc": "2020 IT Month-Taipei City Government Pavilion",
                "location": "Venues | Taipei World Trade Center Exhibition Hall 1",
                "mode": "",
                "link": ""
            },
            {
                "year": 2021,
                "month": 1,
                "date": null,
                "date_text": "January",
                "desc": "GO SMART Online Forum",
                "location": "",
                "mode": "",
                "link": "https://www.citiesgosmart.org/"
            },
            {
                "year": 2021,
                "month": 3,
                "date": 23,
                "date_text": "2020/03/23(Tue) ~ 03/26(Fri)",
                "desc": " 2021 SCSE",
                "location": "Venues | Taipei Nangang Exhibition Center Hall 2",
                "mode": "",
                "link": "https://smartcityonline.org.tw/index.php"
            }
        ]
    }
}