<template lang="pug">
  #hud(ref="hud")
    //- .pos
      p {{mouseStartPos}}
      p {{mouseEndPos}}
</template>

<script>
import p5 from 'p5';
import {mapState} from 'vuex'
export default {
  data(){
    return {
      //正在往前進
      exploring: false,
      currentYear: 2021,
      lastDelta: 0,
      captureToggle: false,
      mouseStartPos: {x: 0, y: 0},
      mouseEndPos: {x: 0, y: 0},
      sketchInstance: null
    }
  },
  props: {
    drawText: {
      type: Boolean,
      default: false
    },
    drawInnerCircle:{
      type: Boolean,
      default: true
    },
    translate: {
      type: Object,
      default: ()=>{
        return {x: 0,y: 0}
      }
    },
    globalScaleRef: {
      type: Number,
      default: 900
    }
  },
  watch: {
    currentEventId(){
      this.exploring = true
      this.setCurrentYearByEvent()
      setTimeout(()=>{
        this.exploring = false
      },1200)
    },
  },
  computed:{
    ...mapState(['currentDate','currentEventId', 'chronology', 'currentMilestoneYear']),
    currentPath(){
      return this.$route.path
    },
  },
  methods: {
    setCurrentYearByEvent(){
      // this.currentYear = this.chronology.find(event => event.id == this.currentEventId)
      // this.currentYear = this.currentMilestoneYear
      // this.currentYear = this.currentDate.getFullYear()+this.currentDate.getMonth()/12
    },
    updateMousePos(evt){
      if(this.captureToggle){
        this.mouseEndPos = {
          x: evt.x,
          y: evt.y
        }
        if(this.mouseStartPos.y - this.mouseEndPos.y > 50 || this.mouseStartPos.y - this.mouseEndPos.y < -50) this.calculateYearDelta()
      }
    },
    captureOn(evt){
      this.captureToggle = true
      this.lastDelta = 0
      this.mouseStartPos = {
          x: evt.x,
          y: evt.y
        }
    },
    captureOff(){
      this.captureToggle = false
    },
    calculateYearDelta(){
      let deltaY = this.mouseStartPos.y - this.mouseEndPos.y
      let movePercentage = deltaY/window.innerHeight
      let fullHeightYear = 6
      let yearDelta = Math.round(movePercentage * fullHeightYear)

      // console.log(yearDelta)
      if(this.lastDelta != yearDelta) this.setYearByDelta( yearDelta - this.lastDelta)
      this.lastDelta = yearDelta
    },
    setYearByDelta(delta){
      if(this.$store.state.currentMilestoneYear + delta <= 2021) {
        this.$store.dispatch("changeCurrentMilestoneYear", this.$store.state.currentMilestoneYear+=delta)
      }
    },
    draw(){
      const sketch = (s) => {
        let angPan = 0
        let innerScale =1
        let globalScale = 1
      

        s.setup = () => {
          var myCanvas = s.createCanvas(window.innerWidth, window.innerHeight);
          s.pixelDensity(2)
          globalScale = s.min(window.innerWidth,window.innerHeight)/this.globalScaleRef
          // globalScale = s.min(window.innerWidth,window.innerHeight)/1200
        }

        s.windowResized = ()=>{
          s.resizeCanvas(window.innerWidth, window.innerHeight);
        }

        s.draw = () => {
          innerScale = s.lerp(innerScale,this.exploring?0:1,0.05)

          s.clear(0,0,window.innerWidth,window.innerHeight)

          s.translate(window.innerWidth/2,window.innerHeight/2)
          s.translate(this.translate.x, this.translate.y)
          s.scale(globalScale)

          //事件的圈圈
          // s.push()
          //   s.noFill()
          //   s.stroke(255)
          //   s.strokeWeight(10)
          //   s.ellipse(0,0,50,50)
          // s.pop()
          // s.strokeWeight(1)
          //其他裝飾
          s.push()
          
            s.push()
            //   s.scale(innerScale)
            //   s.push()
            //     s.stroke(255)
            //     s.strokeWeight(8)
            //     s.noFill()
            //     if (innerScale>0.5){

            //       s.arc(0,0,120,120,0,s.map(innerScale*2,0,1,0,2*s.PI))
            //     }
            //   s.pop()
            //畫圈圈

            if(this.drawInnerCircle){
              for(let i=0;i<5;i++){
                let rr = (10-i)*150
                s.stroke(255,100*s.map(i,0,5,0,0.5))
                s.fill(255,0)
                // s.translate(s.sin(s.frameCount/50+i+s.mouseX)*10,s.cos(s.frameCount/50+i+s.mouseY)*10)
                s.ellipse(0,0,rr,rr)
                s.ellipse(0,0,rr,rr)
              }
            }


            s.pop()
 
            s.push()
              for(let i=0;i<4;i++){
                s.rotate(s.PI/2)
                s.stroke(255,200)
                s.strokeWeight(5)
                s.line(780,0,850,0)
              }
            s.pop()

            s.push()
              // s.scale(s.map(innerScale,0,1,1.02,1))
              //畫刻度
              let segCount = 360
              // 影響總共的年份
              let yearSeg = 8
              // let currentYear = this.currentYear
              let currentYear = this.currentMilestoneYear
              let targetAngPan = -(currentYear-1988)/segCount*yearSeg*2*s.PI
              // let drawText = this.currentPath=='/'
              angPan = s.lerp(angPan,targetAngPan,0.03)

              for(let i=0;i<segCount;i+=1){
                s.push()
                  let ang = angPan+ i*1/segCount*s.PI*2
                  s.rotate(ang)
                  let len = 30
                  let op = 0.4
                  if (i % yearSeg==0){
                    len = 100
                    op = 0.8
                  }
                  s.stroke(255,op*255)
                  if  ( ( s.int(s.frameCount/5)+ i*10 )% 500<490 ){

                    s.line(800,0,800+len,0)
                    if (i%yearSeg==0 && this.drawText ){
                      s.noStroke()
                      s.fill(255,150)
                      s.textSize(20)
                      s.text(1988+i/yearSeg,720,0)

                    }
                  }
                s.pop()
              }
            s.pop()
          s.pop()
        }
    }
    this.sketchInstance = new p5(sketch,'hud');
    }
  },
  created(){
  },
  mounted(){
    this.sketchInstance = null
    this.draw()
  }
}
</script>

<style lang="sass">
#hud
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  pointer-events: none
  z-index: 5
  .pos
    position: absolute
    left: 10px
    bottom: 10px
    color: white
</style>