export default {
    lang: 'En',
    backToHome: '回首頁',
    home: {
        title: "",
        scenes: {
            edu: {
                title: "智慧教育",
                items: {
                    "edu_2": {
                        "title_main": "體溫感測系統",
                        "title_sub": "A Temperature Sensing System",
                        "desc": "於校門口設置體溫感測，學生以數位學生證靠卡RFID後進行非接觸式體溫感測，若體溫過高則進行後續防疫措施。",
                        "link": ""
                    },
                    "edu_1": {
                        "title_main": "臺北酷課網路學校防疫不停學",
                        "title_sub": "Taipei Cool Class Online School Stops Epidemic Prevention",
                        "desc": "臺北市酷課網路學校以學習者為中心打破時空藩籬，提供各科同步及非同步遠距線上教學課程，在疫情升溫期間提供學生數位學習課程兼顧學生學習權，學校行政人員也能掌握教師授課品質及學生學習狀況，並首創跨縣市跨校合作資源共享，實踐臺北酷課網路學校防疫不停學之成效。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=142"
                    }
                }
            },
            health: {
                title: "智慧健康",
                items: {
                    "health_3": {
                        "title_main": "智慧客服小照",
                        "title_sub": "Official Line Account-Xiao Zhao to Learn About Long-term Care",
                        "desc": "「臺北市政府衛生局－找小照了解長照」LINE官方帳號提供民眾可「線上申請」長照服務，使用「我要問問題」，可藉由AI智慧客服自動回覆或引導民眾即時取得所關注長照相關資訊。業務單位可透過訊息主動推播，加強推廣長照資訊與提升政策能見度，成為本局與民眾間有效的溝通管道。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=129"
                    },
                    "health_4": {
                        "title_main": "AI智能化360儀表板",
                        "title_sub": "hiAI-360 Dashboard",
                        "desc": "臺北市立聯合醫院—松德院區（精神照護醫養之家）：以人為中心之「精神照護智能360儀表板」，將AI健康風險分析結合雲端個人健康歸戶，進行自動化綜合性分析，協助醫護人員有效作業，並提供住民全方位個人健促計畫。<br>臺北市立聯合醫院—林中昆院區：「中醫個人化健檢—智能化九型體質儀表板」協助醫師提供個人化體質調理處方，並透過「華人動態慢性病風險分析引擎」結合中醫健檢與西醫臨床之實証醫學綜合性分析，提升整體自我健康管理能力。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=128",
                        "video": "https://www.youtube.com/embed/HLltl6TGTAE"
                    },
                    "health_1": {
                        "title_main": "臺北市預防接種資訊系統",
                        "title_sub": "Taipei Immunization Information System",
                        "desc": "為了提供臺北市民查詢臺北市推辦預防接種政策及相關資訊，輕鬆掌握預防接種大小事。\n 臺北市政府衛生局特別在106年開發「預防接種資訊系統」，方便民眾用電腦或各種行動裝置，即時查詢預防接種補助資格、接種據點及更清楚的了解疫苗及疾病相關衛教資訊，並可透過系統連結，申請E化叮嚀服務提醒小朋友按時接種疫苗。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=136"
                    },
                    "health_2": {
                        "title_main": "區塊鏈於醫療照護之應用",
                        "title_sub": "Blockchain Application in Health Care",
                        "desc": "應用區塊鏈技術打造的「照護資訊整合平台」，讓民眾可運用智慧型手機將自己在各機構的照護資訊，授權給照護人員調閱，讓照護人員即時獲得民眾的健康資訊，延伸民眾的照護範圍與提升整體照護品質；另外，也可使用手機簽署醫療相關同意書，不僅能自動帶入立同意書人資料，簽署地點更不受限制，有效提升簽署效率。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=134",
                        "video": "https://www.youtube.com/embed/Pt-0udUgW-Q"
                    }
                }
            },
            traffic: {
                title: "智慧交通",
                items: {
                    "traffic_1": {
                        "title_main": "GoShare 移動共享服務",
                        "title_sub": "GoShare Mobility Sharing Service",
                        "desc": "共享機車是透過一輛車多人使用，逐步改變市民用車（購車）習慣，以降低私有運具持有，減輕市區停車需求，並作為大眾運輸第一哩及最後一哩之接駁運具。<br>GoShare® 為 Gogoro® 推出的全球首創「端至端」移動共享方案，消費者透過 GoShare App ，就能夠隨時搜尋、預訂、騎乘 Gogoro 智慧雙輪或 Powered by Gogoro NetworkTM 智慧電動機車，滿足日常移動的所有需求，輕鬆享受靈活高效、新奇有趣的騎乘體驗。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=150"
                    },
                    "traffic_2": {
                        "title_main": "自駕巴士創新實驗計畫",
                        "title_sub": "Autonomous Driving Bus Innovative Experiment Plan",
                        "desc": "臺北市與台灣智慧駕駛股份有限公司(簡稱台灣智駕)合作，於信義路公車專用道進行夜間自駕巴士測試，5月開始不載客乘載，驗證本計畫路線運行時可能面臨之各種情境反應，依據測試結果，逐步開放載客測試，最快將於今年9月提供民眾體驗試乘。台灣智駕（Turing Drive）為國內新創團隊，專精自動駕駛系統開發，結合深度學習、感測器融合、平行運算、車輛動態控制等技術。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=155"
                    },
                    "traffic_4": {
                        "title_main": "智慧車聯網及車隊管理系統",
                        "title_sub": "Smart IoT and Fleet Management System",
                        "desc": "共享機車是透過一輛車多人使用，逐步改變市民用車（購車）習慣，以降低私有運具持有，減輕市區停車需求，並作為大眾運輸第一哩及最後一哩之接駁運具。<br>WeMo Scooter 除共享機車外，技術更跨足多元運具，不受限車型品牌、種類、規格可智慧化包含貨運車、四輪汽車、機車、腳踏車等。依據個別運具特性，創造延伸多種市場應用，包含交通運具租借管理、多元支付系統運用、車隊管理調派系統、電池效能管理系統、維修保養系統等，大幅提升城市智慧交通產業效率與動能。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=151"
                    },
                    "traffic_5": {
                        "title_main": "智慧停車",
                        "title_sub": "Smart Parking",
                        "desc": "為提供民眾路邊停車全日即時資訊及行動繳費智慧化服務，規劃智慧停車收費區佈設車輛感測設施，偵測車輛入停及駛離時間，停車收費區現場搭配開單員開立智慧化停車繳費通知單，民眾如收到智慧化停車繳費通知單，駛離車格10分鐘後即可使用手機掃描智慧化停車繳費通知單上之QR-CODE查詢停車資訊並可用智慧支付立即繳費，並可享停車前10分鐘免費之優惠。另提供24小時不間斷的即時資訊，民眾只要下載「北市好停車」APP，即可於即時資訊功能查詢路邊停車格即時停車資訊，預估可以節省民眾5~10分鐘尋找停車位時間。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=156"
                    },
                    "traffic_3": {
                        "title_main": "YouBike公共自行車展示",
                        "title_sub": "Display of Bike Sharing System-YouBike",
                        "desc": "YouBike微笑單車使用電子無人自動化管理系統，提供自行車甲租乙還的租賃服務，盼以自行車做為大眾運輸系統第一哩及最後一哩的接駁工具，藉此鼓勵更多民眾樂意使用大眾運輸系統，同時達到環保與節能的目的，打造全新的台北通勤文化。<br>YouBike 特色<br>一、大眾運輸的子系統<br>二、註冊與使用方便<br>三、優質營運服務<br>四、舒適好騎自行車",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=153"
                    }
                }
            },
            security: {
                title: "智慧安防",
                items: {
                    "security_2": {
                        "title_main": "Smart Policing-「北市智慧警勤APP」2.0",
                        "title_sub": "Smart Policing-TCPD Smart App 2.0",
                        "desc": "為減輕基層員警負擔符合柯市長「智慧融入工作」警政革新政策，本局創全國首例打造「WORK SMART大平臺」：包含「應勤簿冊電子化」、「巡邏箱電子化」、「臨檢盤查」、「交通違規舉發」、「現場影音傳送」等系統，透過手寫板、M-Police警用行動載具、藍芽印表機等設備，使員警執勤「WORK SMART」不再「WORK HARD」，大幅改善執勤環境，提升工作效率。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=145"
                    },
                    "security_1": {
                        "title_main": "消防智慧物聯網",
                        "title_sub": "AIoT-based Smart Fire Alarm System",
                        "desc": "消防智慧物聯網藉由消防安全設備結合人工智慧物聯網技術提供解決方案如下:\n 一、若消防安全設備及防火避難設施因人為因素關閉或破壞，智慧物聯網可即時監控情況並自動辨識出異常，立即通知管理人員。\n 二、若發生火災，亦可透過前述技術，即時通知管理人員、自動語音通報119及傳輸火災現場即時資訊，縮短消防人車派遣時間。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=143"
                    }
                }
            },
            architecture: {
                title: "智慧建築",
                items: {
                    "architecture_4": {
                        "title_main": "創新指標",
                        "title_sub": "Innovation Index",
                        "desc": "本計畫於我國社會宅的興建案例中，唯一獲得智慧建築標黃金級、綠建築標章黃金級及Tiba award金獎，設計理念中於創新指標獲得多項獎項證明，對本案的設計規劃給予實質的肯定。\n 設計理念中，最主要的創新指標共有三大項\n 1.通訊格式一致性\n 2.BIM空間、設備名稱定義\n 3.區域網路IP架構規劃",
                        "link": ""
                    },
                    "architecture_3": {
                        "title_main": "預期潛在效益",
                        "title_sub": "the expected potential efficiency",
                        "desc": "本案園區藉由導入各項智慧化系統，期望提升建物內用戶的工作效率，並有效地降低大樓整體營運成本，預期的潛在效益可分為兩方面:\n 1.費用降低\n •照明系統節能30%以上\n •電梯系統節能30%\n •再生能源每年降低272,457.48kWh用電\n •維護費用降低20%\n 2.收益提升\n •提高租金收益\n •營運效率增加24.6%",
                        "link": ""
                    },
                    "architecture_1": {
                        "title_main": "廣慈智慧社宅整合管理平台",
                        "title_sub": "Guanchi Intelligent Social Residence Integration Management Platform",
                        "desc": "由台北市政府將原廣慈博愛院區進行改建，由於場域太大包含3棟社會住宅、1 棟辦公大樓、1棟社福大樓，因此為整合管理本案ICT系統進行獨立進行發包，以北側ICT系統中建置廣慈智慧社宅整合管理平台(機房設於D棟)，以整合A、B、C、D、E棟之相關BA及設施管理資訊，並配合設施管理及BIM的系統，提供管理人員有效及簡單的畫面管理，進而減少管理人力及園區用電量等功能。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=140"
                    },
                    "architecture_2": {
                        "title_main": "無人機自動駕駛巡邏技術",
                        "title_sub": "Drone Patrol Autopilot Technology",
                        "desc": "廣慈博愛園區智慧社宅無人機自動駕駛巡邏技術，打造智慧社區影像安防新應用。",
                        "link": ""
                    }
                }
            },
            gov: {
                title: "智慧政府",
                items: {
                    "gov_2": {
                        "title_main": "帳單",
                        "title_sub": "Bills",
                        "desc": "繳費免出門免排隊：直接透過台北通APP快速查詢待繳水費、停車費、地方稅及學費帳單，透過支付平台，不用出門僅在掌上就可以完成繳費。完成繳費後，您可以於台北通APP確認花費情形，行動帳本一目瞭然。<br><br>綁定常用資訊：您可以將常用的水號和車號直接綁定到台北通APP，幫您記錄常用資訊，就不用每次都要輸入資料囉，快速又方便完成繳費。",
                        "link": ""
                    },
                    "gov_3": {
                        "title_main": "台北通",
                        "title_sub": "TAIPEI PASS",
                        "desc": "臺北市政府將個人身份識別結合多項市政服務， 打造 【台北通TAIPEI PASS】 即將上線 ，除了是開啟服務的入口，更是專屬臺北市的數位身份識別， 連結每位臺北市民與政府，讓市民服務更加貼近市民生活，以智慧臺北實現城市幸福感。 台北通作為臺北市政府會員單一入口，整合貼近民眾生活的市政便民服務與資源， 建立市民數位ID與認證機制，並連接各項線上與線下服務， 提供市民在各項生活應用上便利的識別及支付工具。 <br><br>加入台北通的會員即可享有臺北市政府提供的服務，台北通成為市民開啟市府服務的鑰匙。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=224"
                    },
                    "gov_1": {
                        "title_main": "服務",
                        "title_sub": "Services",
                        "desc": "免記多組帳號密碼：您可以透過台北通會員單一帳號登入多項線上平台，快速取得跨服務平台授權，輕鬆使用市政服務。",
                        "link": ""
                    },
                    "gov_4": {
                        "title_main": "卡證",
                        "title_sub": "Cards",
                        "desc": "三步驟綁定悠遊卡：只要透過三步驟綁定您的悠遊卡便可以查詢電子發票，確認消費軌跡。綁定之後還可以當台北通優惠卡使用，輕鬆享受台北通的優惠服務。<br><br>虛擬卡帶著走：每次出門是不是都要帶好多卡片才能使用服務？現在你可以使用台北通APP將卡片資訊帶著走，隨時查閱卡片資訊或是出示卡面享受服務。<br><br>*部分卡證優惠及服務仍須透過實體卡片。",
                        "link": ""
                    }
                }
            },
            eco: {
                title: "智慧經濟",
                items: {
                    "eco_1": {
                        "title_main": "智慧支付TPQR共通碼",
                        "title_sub": "Taipei QR",
                        "desc": "TPQR(Taipei QR)提供民眾多元的電子支付方式，除臺北市公共費用、罰鍰外，亦連結動物園、路外智慧停車場、商圈、市場、市府千人以上活動現場等跨縣市、民間場景，並引進更多支付業者，以多元開放的支付生態，共同推廣城市的智慧場景應用。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=185"
                    },
                    "eco_3": {
                        "title_main": "悠遊付功能「商圈微店公有市場」",
                        "title_sub": "Shopping Districts, Microstores, and Public Markets",
                        "desc": "商圈微店公有市場：配合台北市政府推動無現金城市願景，悠遊付積極進駐各商圈、微店及公有市場，落實無現金的智慧生活。推動傳統市場無現金化是市府打造智慧城市的一環，悠遊付進駐知名公有市場（舉例：南門市場），讓市民買菜逛市場可以不用拿油膩的鈔票及銅板，也能多方位抗疫。",
                        "link": ""
                    },
                    "eco_5": {
                        "title_main": "悠遊付功能「未成年使用悠遊付」",
                        "title_sub": "EasyCard for Minors",
                        "desc": "「未成年使用悠遊付」：未滿20歲的未成年人也能註冊悠遊付，只要在２位法定代理人的同意下，即可下載註冊悠遊付，父母可透過悠遊付的轉帳功能，發放零用錢給小孩，不但免手續費，還能透過消費紀錄，讓小孩落實金錢管理，並享有無現金支付的悠遊",
                        "link": ""
                    },
                    "eco_2": {
                        "title_main": "悠遊付功能「嗶乘車」",
                        "title_sub": "Beep Transportation",
                        "desc": "「嗶乘車」是悠遊卡公司推出的獨家功能，讓百萬通勤的上班族，可以選擇用手機搭捷運、或是用手機讓卡片自動加值。民眾在「嗶乘車」功能完成設定後，手機一嗶就能上車，包括：台北捷運、雙北公車以及YouBike微笑單車都能自由使用搭乘。",
                        "link": ""
                    },
                    "eco_4": {
                        "title_main": "悠遊付App",
                        "title_sub": "Easy Wallet",
                        "desc": "悠遊卡是全台交通、消費的好夥伴，深耕20年的無現金支付後，推出全新的電子支付「悠遊付」，要為消費者及商家帶來最棒的電子錢包使用體驗，實現民眾無現生活的美好想像！「Easy Wallet悠遊付」強項包括：手機一嗶就能上車的「嗶乘車」、透過手機解決支付、用戶轉帳等「智慧支付整合」、未滿20歲的未成年人也能註冊悠遊付等多項功能。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=141"
                    }
                }
            },
            env: {
                title: "智慧環境",
                items: {
                    "env_3": {
                        "title_main": "智慧水情展示系統",
                        "title_sub": "Taipei Smart Flood Information and Alerting System",
                        "desc": "臺北智慧水情展示系統，就是臺北市水利處防災人員的行動防汛中心，藉由物連網架構，整合了水利處、中央氣象局、十河局、大地處等單位共計86處雨量站、104站河川水位站、139站下水道水位站、64處監視器、87座抽水站的抽水感測資訊，並結合最新的氣象觀測資料（衛星雲圖、雷達回波圖、潮汐預報），建構完整的防汛決策支援系統。\n 並藉由資料整合，發送自動警訊通知，自動產生水情報告，克服人力難以監控及查閱大量資料的缺點，本系統並可提供不同防災單位隨選客製版面，搭配臺北水情APP，隨時提醒防災人員快速掌握臺北市的水情狀況。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=146"
                    },
                    "env_2": {
                        "title_main": "道路管線智慧化管理",
                        "title_sub": "Road Pipeline by Smart Management",
                        "desc": "為解決經常挖掘道路，影響道路服務品質問題，臺北市政府於104年7月1日成立「臺北市道路管線暨資訊中心」（簡稱「道管中心」）。優先針對道路維護更新，管線汰換，天空纜線清整，整合各項市政建設及新建房屋民生管線等計畫與需求。協調統一挖補並對道路施工預做排程管理，避免管線機構之間疏於橫向溝通聯繫，造成施工頻繁及施工衝突而影響交通，同時也嚴格限制夜間施工，避免影響居家安寧。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=147"
                    },
                    "env_1": {
                        "title_main": "邊坡智慧監測與管理",
                        "title_sub": "The Digital Mornitoring and Management in Slopeland",
                        "desc": "展出本處應用多項自動化監測及觀測技術之成果，包含物聯網人工邊坡監測系統、山崩與地滑地質敏感區自動化監測系統以及山坡地水文即時觀測系統，並輔以視覺化平台展示監測數據。",
                        "link": "https://smartcityonline.org.tw/scheme.php?id=148"
                    }
                }
            }
        }
    },
    about: {
        title: "關於我們",
        desc: "臺北市是一座充滿活力的城市：它已經制定了優質的城市生活願景，持續以不同的城市更新計畫來往智慧化邁進。 為了繼續讓台北市邁向宜居城市，臺北市政府以“開放政府”，“公民參與”和“公私協力”作為發展智慧城市專案的核心願景。 同時，臺北市採用沙盒方法，鼓勵創新者將臺北市當作實驗地，幫助產業加快各種解決方案的發展，並為市民提供良好的居住環境。",
        brochure: "Smart Taipei 智慧城市成果手冊",
        brochure_url: "https://drive.google.com/file/d/14UYCuGkfk25rkOlEUat99r5qIbZMR9qw/view",
        video: "https://www.youtube.com/embed/2IwUFtX9TUI"
    },
    explore: {
        title: "發現台北",
        intro: "臺北是個具有深厚歷史人文足跡的城市，我們看見這裡的繁華速迅，但也別忽略了這城市悠活自在的一面。<br><br>讓我們一起用科技發現臺北之美。",
        sights: {
            songyan: {
                name: "松山文創園區",
                name_en: "Songshan Cultural and Creative Park",
                desc: "肩負帶動臺北市原創力、強化文創軟實力且提升城市競爭力為使命的松山文創園區，定位為「臺北市原創基地」，2011年正式對外開放，扮演文創「社會企業」角色，規劃「跨界實驗」、「共好平台」、「創意學院」、「創意櫥窗」、「創作者工廠」五大創新策略領航，藉由「軟實力創新」、「社群網絡連結」、「品牌價值經營」、「人才養成」面向達成「臺北市原創基地」目標，培養原創人才及原創力。"
            },
            beimen: {
                name: "北門",
                name_en: "North Gate (Beimen)",
                desc: "臺北古城有一共有5個城門：東門（景福門）、西門（寶成門）、南門（麗正門）、小南門（重熙門）、北門（承恩門），是清光緒年代，劉銘傳時期所建造，鼓勵商人在臺北城內，投資興建房舍，形成街道，活絡發展。<br>近年建設規劃中原要被拆除的北門，隨著保存文化遺產的觀念興起，於是原樣留下了北門古蹟，北門成為5門當中，唯一以清代風貌保留的古城門，是臺北最珍貴的國定古蹟之一，也由於早期具有的防禦功能，成為國防的歷史見證。"
            },
            honglou: {
                name: "西門",
                name_en: "Ximen",
                desc: "臺北的街頭時尚哪裡找？那就到西門町吧！歷久不衰的西門商圈，是年輕人喜愛挖寶的地區，從美食、服飾到生活用品，都能用相對便宜的價錢得到。在這裡也能看見臺北的街頭文化，塗鴉、舞蹈…等，西門絕對能說是臺北代表地區之一。"
            },
            longshan: {
                name: "龍山寺",
                name_en: "Lungshan Temple",
                desc: "萬華，是臺北開發最早的地方，當今雖然沒有以往興盛，卻保留著很道地的＂臺北味＂，其中信仰中心－龍山寺，以許願靈驗及精雕細琢的廟宇聞名，吸引許多外籍旅客前往參拜！ <br>龍山寺周遭有相當多的傳統美食，經營一甲子的老店舖在這裡可不少，等待旅客前來體驗不一樣的臺北！"
            },
            moma: {
                name: "中山",
                name_en: "Zhongshan",
                desc: "捷運中山站文創小店聚落、地下街帶給此區文藝氣息，心中山線形公園的綠蔭廊道、赤峰街的小巷弄個性小店，成為拍照族必遊朝聖之地。中山站附近的台北光點曾為多位美國大使官邸，而今則以電影文化為主題，轉化成展演、咖啡館等多元功能場所、蔡瑞月舞蹈研究社、各種展覽空間及咖啡館，是許多文青流連之處。在此區漫步，處處皆有驚喜。"
            },
            101: {
                name: "台北101",
                name_en: "Taipei 101",
                desc: "臺北市的精華地帶非信義區莫屬了，屬於台灣精要商辦地區，高樓大廈林立，許多國際商務人士在此往來，台灣重要地標101大樓就位於信義區！信義商圈呈現最前端的國際時尚，多數百貨公司都立足在此，也有像是以老眷村改造的四四南村，不時有文創手作市集邀請旅客參與！"
            },
            dadaocheng: {
                name: "大稻埕",
                name_en: "Dadaocheng",
                desc: "傳統的老臺北要往哪裡去？絕對不能錯過大稻埕！過去是臺灣南北貨物集結、販售的地方，現在仍然是臺北人購買乾貨、原物料的好去處；大稻埕保留下許多百年建築，經過整理後招租，已經成為臺北新興文創集散地！走進大稻埕老街，體會新舊文化碰撞的驚喜"
            }
        }
    },
    prevent: {
        "title": "科技防疫",
        "subtitle": "Technology Against COVID-19",
        "prevent_1": {
            "title": "實名制口罩自動販賣系統",
            "desc": "「實名制口罩自動販賣系統」試辦計畫，提倡整合創新、提昇效率、便民服務三大目標，以及提供實名制服務以及推動無現金交易，以配合中央口罩購買管制及降低疫情期間之現金接觸感染。\n 為推動無現金交易，實名制口罩販賣機、及智慧化行動模組皆提供悠遊卡等便利安全的付款方式，並鼓勵民眾優先使用悠遊卡，以加速購買流程。",
            "link": "https://smartcityonline.org.tw/scheme.php?id=137"
        },
        "prevent_2": {
            "title": "防疫國際交流",
            "desc": "新型冠狀病毒肺炎(COVID-19)疫情蔓延全球，臺灣在這次疫情控制上的傑出表現，獲得國際社會矚目並有意學習我國防疫之相關作法，臺北市透過線上平台、線上會議等方式，將臺北市因應新冠肺炎疫情之各項創新防疫經驗及措施分享予國際城市，對開拓及深化國際城市交流，意義重大。",
            "link": "https://smartcityonline.org.tw/scheme.php?id=198"
        },
        "prevent_3": {
            "title": "異地辦公及視訊會議",
            "desc": "建置臺北市政府遠端辦公服務網，整合公告遠端辦公相關資源與使用方式，並提供員工異地辦公時，可使用自備之資訊設備，搭配網頁上執行相關套件，達成使用公務系統需求；若有會議需求，亦提供遠距視訊會議系統，使市府於疫情期間亦能順利推動市政服務。",
            "link": "https://smartcityonline.org.tw/scheme.php?id=172"
        },
        "prevent_4": {
            "title": "新冠肺炎資訊整合儀表板",
            "desc": "因應新冠肺炎疫情，資訊局提供府級長官、緊急應變小組即時的資訊整合儀表板，整合國內外相關疫情資料、中央健保署的藥局口罩庫存量、口罩販賣機銷售量、各公有場館進場人數及防疫相關議題聲量等。主要呈現內容強調跨局處的全局性及解決資訊分散的問題，並以地理空間資訊的方式呈現讓首長瀏覽即可得知必要的疫情資訊及各行政區的狀況，作為決策的參考依據。",
            "link": "https://smartcityonline.org.tw/scheme.php?id=175"
        },
        "prevent_5": {
            "title": "場館實名制系統",
            "desc": "配合中央疫情指揮中心指示，在各場館逐步解封之際，如何因應可能的疫調作為是解封的必要處理項目，資訊局開發實名制APP(myCode)，提供本府所轄各公共場館以掃瞄證件條碼方式，快速完成實名登記作業，APP快速部署的特性，讓場館無須系統軟硬體的開發成本，而個資由資訊局加密封存保管，讓個資有完善的保護。",
            "link": "https://smartcityonline.org.tw/scheme.php?id=225"
        }
    },
    events: {
        date_dsc: "日期",
        location_dsc: "展場/地點",
        mode: "會議進行模式",
        events: [{
                "year": 2020,
                "month": 8,
                "date": 13,
                "date_text": "2020/08/13 (四) 17:00 ~ 18:40 (GMT+8)",
                "desc": "智慧城市首長高峰會",
                "location": "智慧城市展官方 YouTube 頻道",
                "mode": "直播, 全程英文",
                "link": "https://smartcityonline.org.tw/forum_detail.php?id=8f14e45fceea167a5a36dedd4bea2543"
            },
            {
                "year": 2020,
                "month": 8,
                "date": 18,
                "date_text": "2020/08/18(二) 07:00 ~ 08:40 (GMT+8)",
                "desc": "智慧城市首長高峰會",
                "location": "智慧城市展官方 YouTube 頻道",
                "mode": "直播, 全程英文",
                "link": "https://smartcityonline.org.tw/forum_detail.php?id=aab3238922bcc25a6f606eb525ffdc56"
            },
            {
                "year": 2020,
                "month": 8,
                "date": 19,
                "date_text": "2020/08/19 (三) 17:00 ~ 18:40 (GMT+8)",
                "desc": "智慧城市首長高峰會",
                "location": "智慧城市展官方 YouTube 頻道",
                "mode": "直播, 全程英文",
                "link": "https://smartcityonline.org.tw/forum_detail.php?id=9bf31c7ff062936a96d3c8bd1f8f2ff3"
            },
            {
                "year": 2020,
                "month": 9,
                "date": 1,
                "date_text": "2020/09/01(二)",
                "desc": "北臺聯盟<br>智慧城市產業合作交流會",
                "location": "",
                "mode": "",
                "link": ""
            },
            {
                "year": 2020,
                "month": 9,
                "date": 2,
                "date_text": "2020/09/02(三)",
                "desc": "Smart City Online<br>開幕暨頒獎典禮",
                "location": "",
                "mode": "",
                "link": "https://smartcityonline.org.tw/"
            },
            {
                "year": 2020,
                "month": 9,
                "date": 8,
                "date_text": "2020/09/08(二) ",
                "desc": "智慧城市數位治理論壇",
                "location": "",
                "mode": "",
                "link": ""
            },
            {
                "year": 2020,
                "month": 9,
                "date": null,
                "date_text": "9月份",
                "desc": "GO SMART<br>線上主題論壇",
                "location": "",
                "mode": "",
                "link": "https://www.citiesgosmart.org/"
            },
            {
                "year": 2020,
                "month": 9,
                "date": 22,
                "date_text": "2020/09/22(二) 17:00 ~ 18:40",
                "desc": "CTalk城市交流論壇 醫療",
                "location": "智慧城市展官方 YouTube 頻道",
                "mode": "直播, 全程英文",
                "link": "https://smartcityonline.org.tw/forum_detail.php?id=70efdf2ec9b086079795c442636b55fb"
            },
            {
                "year": 2020,
                "month": 9,
                "date": 23,
                "date_text": "2020/09/23(三) 17:30 ~ 18:40",
                "desc": "CTalk城市交流論壇 新創",
                "location": "智慧城市展官方 YouTube 頻道",
                "mode": "直播, 全程英文",
                "link": "https://smartcityonline.org.tw/forum_detail.php?id=6f4922f45568161a8cdf4ad2299f6d23"
            },
            {
                "year": 2020,
                "month": 9,
                "date": 24,
                "date_text": "2020/09/23(四) 17:30 ~ 18:40",
                "desc": "CTalk城市交流論壇 市政",
                "location": "智慧城市展官方 YouTube 頻道",
                "mode": "直播, 全程英文",
                "link": "https://smartcityonline.org.tw/forum_detail.php?id=1f0e3dad99908345f7439f8ffabdffc4"
            },
            {
                "year": 2020,
                "month": 12,
                "date": 2,
                "date_text": "2020/12/02(三) ~ 12/06(日)",
                "desc": "109 資訊月<br>台北市政府主題館",
                "location": "展場 | 台北世貿1館",
                "mode": "",
                "link": ""
            },
            {
                "year": 2021,
                "month": 1,
                "date": null,
                "date_text": "1月份",
                "desc": "GO SMART<br>線上主題論壇",
                "location": "",
                "mode": "",
                "link": "https://www.citiesgosmart.org/"
            },
            {
                "year": 2021,
                "month": 3,
                "date": 23,
                "date_text": "2021/03/23(二) ~ 03/26(五)",
                "desc": "2021 智慧城市展",
                "location": "展場 | 南港展覽館 2 館",
                "mode": "",
                "link": "https://smartcityonline.org.tw/index.php"
            }
        ]
    }
}