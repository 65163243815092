<template lang="pug">
#event(:class="{active: eventActive}")
  .container-fluid
    //- Right brief
    
    .row
      .col-sm-6.mt-5
        transition(name="fadeIn")
          .event-content(:key="useEvent.title")
            h4
              span.mx-2(v-for="keyword in useEvent.keywords") {{ keyword }}
            h2.mt-2(v-html="useEvent.title")
            h5.mt-1.mb-2 {{ useEvent.date || (useEvent.year+"/1/1") }} 
            .event-brief.mt-3
              div(v-html="useEvent.contentSummary")
              br
              button.btn(@click="showMore = true" v-if="event.content && event.show_readmore!==false") 閱讀更多

      .col-sm-6
        //- Media
        transition(name="fadeIn")
          .row.vh-100.vw-100.m-0.media(:key="useEvent.title")
            MediaBox.media-box.media-0.ml-5(
                :width="500" :height="400" 
                :images="images" :offset="{x: 0, y: 0}"
                :class="{portrait: useEvent.image_direction === 'portrait'}"
                )
            //- p {{images}}

    //- Extended contents
    .row.vh-100.vw-100.position-absolute.m-0.extended-content
      TextBox.text-box-1(v-if="showMore" @close="showMore=false" ,
                         :content="useEvent.content")
      
</template>

<script>
import MediaBox from "@/components/MediaBox";
import TextBox from "@/components/TextBox";

export default {
  props: {
    event: {
      type: Object,
      default: ()=>{}
    }
  },
  components: {
    MediaBox,
    TextBox
  },
  data() {
    return {
        showMore: false,
        eventActive: false,
        // images: [{
        //   url: require('@/assets/images/media-0.png'),
        //   caption: "圖片1說明"
        // },{
        //   url: 'https://fakeimg.pl/440x320/282828/eae0d0/?retina=1',
        //   caption: "圖片2說明"
        // }
        // ]
    };
  },
  watch:{
    event(){
      //更換資料後的delay軌道時間 -> 事件再進來
      this.eventActive=false
      setTimeout(()=>{
        this.eventActive=true
      },1500)
    }
  },
  computed:{
    useEvent(){
      //前資料處理
      // console.log(this.event.image_direction)
      return {  
        ...this.event,
        title: this.event.title.replace(/。/g,"")
                               .replace(/ ?- ?/g," - ")
                               .replace(/ ?\(/g," (")
                               .replace(/\) ?/g,") ")
                              //  .replace(/[，：]/g,"<br>")
                              ,
        content: this.event.content.replace(/\n/g,'<br>'),
        contentSummary: this.event.content.slice(0,300).replace(/\n/g,'<br>') + (this.event.content.length > 300 ? '...': '')
      }
    },
    images(){
      return (this.event.images || []).map(
        (relativeUrl,id)=>({
          url: relativeUrl
        }))
    }
  },
  mounted() {
  }
};
</script>

<style lang="sass">
#event  
  color: #FFFFFF
  z-index: 10
  .event-content
    height: 100%
    text-align: left
    position: relative
    z-index: 100
    padding-top: 50px
    pointer-events: none
    
    h2
      font-weight: 700
      line-height: 1.4
      max-width: 650px
      word-break: break-word
      text-shadow: 0px 0px 20px rgba(black,1)
    h4
      color: black
      background-color: #fff
      width: fit-content
      padding: 0 5px 0 10px
    p 
      text-shadow: 0px 0px 20px rgba(black,1)
      background: linear-gradient(270deg,rgba(black,0.9) 0% , rgba(black,0.5) 100%)
      padding: 10px
      padding-left: 0px
    .event-brief
        position: relative
        width: 30vw
        max-width: 600px

        span
            font-weight: 300
        button
            color: white
            border: solid 1px white
            border-radius: 0
            z-index: 10
            pointer-events: auto
            &:hover
                cursor: pointer
        // &:after
        //     content: ''
        //     position: absolute
        //     bottom: 0
        //     left: 0
        //     width: 40%
        //     height: 6px
        //     background-color: #fff
  .media, .extended-content
      top: 0
      left: 0
      z-index: 500
      position: relative
      pointer-events: none
      overflow: hidden
      .media-0
          position: absolute
          bottom: 50%
          left: 100px
          z-index: 10
      .text-box-2
          position: absolute
          top: 40%
          left: 10%
      .text-box-1
          position: absolute
          top: 30%
          left: 35%
  .media
    top: 50px
    left: inherit
    right: 0px

  //進出動畫用
  .event-content,.media
    opacity: 0
    transition: 1.1s
  .media
    transition-delay: 0.2s
  .event-content
    transform-origin: 90% 50%
    transform: scale(0.5) translateX(300px) 
  .media
    transform-origin: 0% 50%
    transform: scale(0.5) translateX(-300px) 
  
  &.active
    .event-content,.media
      opacity: 1
      transform: scale(1)
    
  .media-box
    .VueCarousel .VueCarousel-wrapper .VueCarousel-slide div.slide-image, .VueCarousel .VueCarousel-wrapper .VueCarousel-slide .slide-video
      background-size: contain
      background-color: transparent
</style>