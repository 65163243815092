<template lang="pug">
  #pug
    .container
      h1 Welcome to Monoame Vue Starter Kit
      br
      br
      h2.locale Current Locale: {{$i18n.locale}}
      button.btn.btn-dark(@click="setLocale") Click to set locale
      br
      br
      .custom-style Set custom style and override bootstrap style in @/assets/css/custom
</template>

<script>
export default {
  name: "componentExample",
  props: {
    msg: String
  },
  methods: {
    setLocale() {
      this.$i18n.locale = this.$i18n.locale == "zh" ? "en" : "zh";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
h3
  margin: 40px 0 0
ul
  list-style-type: none
  padding: 0
li
  display: inline-block
  margin: 0 10px
a
  color: #42b983
</style>
