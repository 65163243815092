<template lang="pug">
  div
    #three_bg
    //- button(@click="myApp.prev();deltaCurrentEventId(-1)") 往前
    //- button(@click="myApp.next();deltaCurrentEventId(+1)") 往後
</template>

<script>
import {LightRailApp} from "@/assets/Infinite-Lights-master/js/InfiniteLights.js"
import {LongRaceDistortion} from "@/assets/Infinite-Lights-master/js/Distortions.js"
export default {
  data(){
    return {
      myApp: null
    }
  }, 
  methods:{
    next(){
      this.myApp.next()
      //光條走速控制
      this.myApp.setElapseTimeFactor(1)
      setTimeout(()=>{
        this.myApp.setElapseTimeFactor(0.1)
      },1600)
    },
    prev(){
      this.myApp.prev()
      //光條走速控制
      this.myApp.setElapseTimeFactor(1)
      setTimeout(()=>{
        this.myApp.setElapseTimeFactor(0.1)
      },1600)
    }
  },
  mounted(){
    const container = document.getElementById('three_bg')
    
    const options = {
      onSpeedUp: (ev) => {					
      },
      onSlowDown: (ev) => {
      },
      // mountainDistortion || LongRaceDistortion || xyDistortion || turbulentDistortion || turbulentDistortionStill || deepDistortionStill || deepDistortion
      distortion: LongRaceDistortion, 
      
      length: 600,
      roadWidth: 10,
      islandWidth: 5,
      lanesPerRoad: 2,

      fov: 90,
      fovSpeedUp: 150,
      speedUp: 2,
      carLightsFade: 0.5,

      totalSideLightSticks: 50,
      lightPairsPerRoadWay: 70,

      // Percentage of the lane's width
      shoulderLinesWidthPercentage: 0.05,
      brokenLinesWidthPercentage: 0.1,
      brokenLinesLengthPercentage: 0.5,

      /*** These ones have to be arrays of [min,max].  ***/
      lightStickWidth: [0.12, 0.5],
      lightStickHeight: [1.3, 1.7],

      movingAwaySpeed: [60, 80],
      movingCloserSpeed: [-120, -160],

      /****  Anything below can be either a number or an array of [min,max] ****/

      // Length of the lights. Best to be less than total length
      carLightsLength: [600 * 0.05, 600 * 0.15],
      // Radius of the tubes
      carLightsRadius: [0.05, 0.14],
      // Width is percentage of a lane. Numbers from 0 to 1
      carWidthPercentage: [0.3, 0.5],
      // How drunk the driver is.
      // carWidthPercentage's max + carShiftX's max -> Cannot go over 1. 
      // Or cars start going into other lanes 
      carShiftX: [-0.2, 0.2],
      // Self Explanatory
      carFloorSeparation: [0.05, 1],

      colors: {
        roadColor: 0x080808,
        islandColor: 0x0a0a0a,
        background: 0x080B1D,
        shoulderLines: 0x131318,
        brokenLines: 0x131318,
        /***  Only these colors can be an array ***/
        leftCars: [0xFF5F73, 0xE74D60, 0xff102a],
        rightCars: [0xA4E3E6, 0x80D1D4, 0x53C2C6],
        sticks: 0xA4E3E6,
      }
    };

    this.myApp = new LightRailApp(container, options);
    this.myApp.loadAssets().then(this.myApp.init)
  }
}
</script>

<style lang="sass">

#three_bg
  width: 100%
  height: 100vh
  overflow: hidden
  position: absolute
  left: 0
  top: 0
  z-index: -1
button
  position: relative
  z-index: 50
</style>