<template lang="pug">
.glitch-container(
  :style="{width: width!=0?(width+'px'):'',height: height!=0?(height+'px'):''}",
  @mouseenter="generateGlitches")
  .glitch(
      v-for="glitch in glitches",
      :class="glitch.type",
      :style="{left: glitch.x+'%', top: glitch.y+'%', width: glitch.w+'%',height: glitch.h+'%'}" )

</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    count: {
      type: Number,
      default: 5
    },
    size: {
      type: Number,
      default: 15
    },
    static: {
      type: Boolean,
      default: false
    },
    seed: {
      type: Number,
      default: 0
    },
  },
  data(){
    return {
      interval: null,
      glitches: [], 
    }
  },
  mounted(){
    // for(var i=0;i<this.count;i++){
    //   let w =  Math.random()*10
    //   this.glitches.push({
    //     x: Math.random()*100,
    //     y: Math.random()*100,
    //     w: w,
    //     h: 15/w,
    //     type: i%2==0?'red':'blue'
    //   })
    // }
    if (!this.static){
      this.interval = setInterval(()=>{
        this.generateGlitches()
      },150)

    }else{
      this.generateGlitches()
    }

  },
  methods: {
    generateGlitches(){
      this.glitches = []
      for(var i=0;i<this.count;i++){
        let w =  Math.random()*10+2
        this.glitches.push({
          x: Math.random()*100,
          y: Math.random()*100,
          w: w,
          h: this.size*(0.5+Math.random()*0.5)/w,
          type: i%2==0?'red':'blue'
        })
      }
    }
  },
  watch: {
    seed(){
      this.generateGlitches()
    }
  },
  beforeDestroy(){
    clearInterval(this.interval)
  }
}
</script>

<style lang="sass">
.glitch-container
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  .glitch
    position: absolute
    &.blue
      background-color: #71DCE2
      box-shadow: 0px 0px 15px 0px #71DCE2
    &.red
      background-color: #FF3861
      box-shadow: 0px 0px 15px 0px #FF3861
</style>
