<template lang="pug">
  #app.noselect
    .nav-icon
      span.animate__animated.animate__fadeIn(:key="pageName" v-html="pageName")
    transition(name="fade" mode="out-in")
      #nav.float-right
        router-link(to='/')
          img.icon-home(src="/img/home-icon.png")
        a(@click="$router.push({name:'Milestone'})" v-if="$route.path.includes('explore')")
          img.icon-back(:src="require('@/assets/images/icons/back.svg')")
        img.icon-volumn(:src="volumnIcon" @click="clickVol")
    router-view.content-area
    audio#bgmusic(src="/bgloop.mp3" autostart="0" loop ref="bg-music")
</template>

<script>
import {data} from "@/assets/data/chronology.js"
import {gsap} from "gsap"
const Stats = require('stats.js')
const axios = require('axios')
import {mapState,mapMutations} from 'vuex'

export default {
  name: "App",
  components: {},
  data: ()=>{
    return{}
  },
  computed: {
    ...mapState(['isBgMusicMuted']),
    pageName() {
      if (this.$route.path == "/") {
        return "THE HISTORY OF<br>NCHC";
      }
      if (this.$route.path == "/about") {
        return "THE INFO OF<br>NCHC";
      }
      if (this.$route.path == "/videos") {
        return "THE FILMS OF<br>NCHC";
      }
      if (this.$route.path == "/milestone") {
        return "MILESTONES";
      }
      return "HISTORY";
    },
    volumnIcon(){
      return this.isBgMusicMuted ? require('@/assets/images/icons/mute.svg') : require('@/assets/images/icons/volume.svg')
    }
  },
  created(){
    // if(process.env.NODE_ENV === 'development')
    this.setupStats()

    // this.$store.dispatch("updateChronologyData",data)
    axios.get(process.env.VUE_APP_API_URL+'/events?_sort=date:ASC').then(res=>{
      return res.data.map((event, index) => {
        event.year = parseInt(event.year)
        event.images = event.images.map(img => process.env.VUE_APP_API_URL + img.url)
        event.keywords = event.keywords.split(',')
        event.date = event.date ? event.date.replace(/-/g,'/') : null
        event.cat = event.category
        return event
      })
    })
    .then(events => {
      this.$store.dispatch("updateChronologyData",events)
    })
    .catch(err=>{
      console.log(err)
    })
    
  },
  mounted(){
  },
  methods: {
    ...mapMutations(['toggleBgMusic']),
    clickVol(){
      const bgMusicEl = this.$refs['bg-music']
      this.toggleBgMusic()
    },
    setupStats(){
      function animate() {
        stats.begin();
        // monitored code goes here
        stats.end();
        requestAnimationFrame( animate );
      }

      if(process.env.NODE_ENV==='development'){
        var stats = new Stats();
        stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
        document.body.appendChild( stats.dom );
        requestAnimationFrame( animate );
      }
    
    },
    toggleFullScreen(){
      if (!document.exitFullscreen){
        document.documentElement.requestFullscreen();
        location.reload();
      }
      
    }
  },
  watch: {
    isBgMusicMuted(){
      if(this.isBgMusicMuted){
        gsap.to(this.$refs['bg-music'], 2 ,{
          volume: 0,
          onComplete: ()=>{
            this.$refs['bg-music'].pause()
          },
        })  
      }else{
        gsap.to(this.$refs['bg-music'], 2 ,{
          volume: 0.2,
          onComplete: ()=>{
            this.$refs['bg-music'].play()
          },
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-icon {
  position: fixed;
  left: 50px;
  top: 30px;
  color: white;
  font-weight: 700;
  font-size: 32px;
  font-family: "Noto Sans";
  letter-spacing: 3px;
  z-index: 2;

  &:after {
    content: "";
    display: block;
    margin-top: 15px;
    height: 20px;
    width: 4px;
    background-color: #fff;
  }
}
#nav {
  padding: 30px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;

  * {
    color: white;
    margin-right: 30px;
  }
}
.content-area {
  padding-top: 100px;
  padding-bottom: 0px;
  // height: calc(100% - 110px)
}
.icon-home{
  width: 60px;
  cursor: pointer;
}
.icon-back{
  width: 50px;
  margin-left: 5px;
  cursor: pointer;
}
.icon-volumn{
  width: 45px;
  margin-left: 10px;
  cursor: pointer;
}
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: 0.5s;
  // transform: translateY(0px);
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  // transform: translateY(50px);
  opacity: 0;
}
</style>
