<template lang="pug">
//-  @click.stop="()=>{}"
.box(:style="getStyle()" v-if="images && images.length" @click="clickImage")
  carousel.mt-4(
    :per-page="1",
    :autoplay="true",
    :autoplayTimeout="3000",
    :loop="true",
    :style="{ transform: `translate(${offset.x}px,${offset.y}px) scale(${scale})` }",
    :pagination-enabled="images.length > 1"
    ref="carousel"
  )
    slide(v-for="(image, index) in images", :key="'img_' + index")
      div.slide-image(v-if="mediaType==='image'" :style="{ backgroundImage: `url('${image.url}')` }")
      div.slide-video(v-if="mediaType==='video'")
        video(volume="0" autoplay muted)
          source(:src="videos[0]['url']")

  TextBox(v-if="showCaption && images[currentIndex].caption"
           :content="images[currentIndex].caption", :showTitle="false", :canClose="false")
</template>

<script>
import TextBox from "@/components/TextBox";
import { Carousel, Slide } from "vue-carousel";
import { TimelineMax} from "gsap";

export default {
  props: {
    width: {
      default: 500
    },
    height: {
      default: 500
    },
    strokeWidth: {
      default: 1
    },
    showCaption:{
      type: Boolean,
      default: true
    },
    mediaType:{
      type: String,
      default: 'image'
    },
    imgDirection: {
      type: String,
      default: 'landscape'
    },
    videos: {
      default: () => [
        {
          url: require("@/assets/images/video/demo_video.mp4"),
          caption: "圖片1說明"
        }
      ]
    },
    images: {
      default: () => [
        {
          url: "https://fakeimg.pl/440x320/282828/eae0d0/?retina=1",
          caption: "圖片1說明"
        },
        {
          url: "https://fakeimg.pl/200x320/282828/eae0d0/?retina=1",
          caption: "圖片2說明"
        }
      ]
    },
    offset: {
      default: ()=>{return {x: 0, y: 0} }
    },
    scale:{
      default: 1
    }
  },
  components: {
    TextBox,
    Carousel,
    Slide
  },
  data() {
    return {
      displayCarousel: false,
      caption: "圖片說明", 
    };
  },
  computed: {
    currentIndex: {
      cache: false,
      get() {
        return this.$refs.carousel ? this.$refs.carousel.currentPage : 0;
      }
    }
  },
  watch: {
  },
  methods: {
    getStyle() {
      let style = {
        width: this.width + "px",
        height: this.height + "px",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg width="${
          this.width
        }" height="${this.height}" viewBox="0 0 ${this.width} ${
          this.height
        }" stroke-width="${
          this.strokeWidth
        }" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0" y="0" width="${
          this.width
        }" height="${this.height}" stroke-width="${
          this.strokeWidth * 2
        }" stroke="white"/><line x1="0" y1="0" x2="${this.width}" y2="${
          this.height
        }" stroke="white"/><line x1="${this.width}" y1="0" x2="0" y2="${
          this.height
        }"  stroke="white"/></svg>')`
      };
      return style;
    },
    clickImage(){
    }
  },
  mounted() { 
  }
};
</script>

<style lang="sass">
.VueCarousel
  height: 100%
  width: 100%
  .VueCarousel-wrapper
    height: 95% !important
    width: 100%
    transform: translate(-20px,-20px) !important
    .VueCarousel-inner
      height: 100% !important
      width: 100%
    .VueCarousel-slide
      div.slide-image, .slide-video
        // width: 100%
        height: 100%
        width: auto
        background-size: cover
        background-repeat: no-repeat
        background-position: center
        background-color: white
  .VueCarousel-pagination
    .VueCarousel-dot-container
      transform: translateY(-15px)
      margin-top: 0px !important
      margin-right: 30px 
      float: right
    button
      margin-top: 0 !important
      background-color: rgb(0,0,0) !important
      position: relative
      &:focus
        outline: none
      &.VueCarousel-dot--active
        background-color: rgb(255, 255, 255) !important
      &::after
        content: ''
        position: absolute
        width: 10px
        height: 10px
        border-radius: 50%
        border: solid 1px white
        left: 50%
        top: 50%
        transform: translate3d(-50%, -50%, 0)
.box
  background-size: 100% 100%
  position: relative
  display: flex
  align-items: center
  justify-content: center
  pointer-events: auto
  img
    max-width: 120%
  .text-box
    overflow: initial
    position: absolute
    top: 105%
    right: 10%
    padding: 0 1rem !important
    height: 36px
    line-height: 36px
    font-size: 1rem
  video
    width: 100%
    height: 100%
</style>