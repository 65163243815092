import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "regenerator-runtime/runtime";
import "bootstrap"; // Import js file
import "@fortawesome/fontawesome-free/js/all.js";

import "modern-css-reset/dist/reset.min.css";
import "@/assets/css/style.scss";
import "@/assets/css/custom.scss";
import "animate.css";
import axios from "axios";
import VueI18n from "vue-i18n";
import langs from "./assets/langs";
import VueGtag from "vue-gtag";
import Bowser from "bowser";
import "./assets/js/preload";
import VueCarousel from "vue-carousel";

Vue.use(VueCarousel);

// global var settings
window.router = router;

// Set up axion
window.axios = axios;
window.Vue = Vue;

// Set up browser
const browser = Bowser.getParser(window.navigator.userAgent);
store.commit("setBrowser", browser.getBrowser());

// Set up i18n
const locale = localStorage.getItem("lang") || "zh";
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale,
  messages: langs
});

// Set up Google Analytics
const GAID =
  process.env.NODE_ENV === "development"
    ? "UA-113459032-1"
    : process.env.VUE_APP_GAID;

// Vue.use(VueGtag, {
//   config: {
//     id: GAID
//   },
//   includes: [{
//     id: 'UA-1234567-2' //multiple trackers
//   }]
// }, router);

Vue.config.productionTip = false;
new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
  mounted() {
    // build prerendered site
    // document.dispatchEvent(new Event("render-event"));
  }
}).$mount("#app");
