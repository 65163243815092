import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Test from "../views/Test.vue";
import Videos from "../views/Videos.vue";
// import Particles from "../views/Particles.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/explore/:eventId",
    name: "Explore",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/videos",
    name: "Videos",
    component: Videos
  },
  {
    path: "/test",
    name: "Test",
    component: Test
  },
  {
    path: "/milestone",
    name: "Milestone",
    component: () => import("../views/Milestone.vue")
  }
  // {
  //   path: "/particles",
  //   name: "Particles",
  //   component: Particles
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
