<template lang="pug">
  .home
    .content.text-center
      transition(name="fade" mode="out-in")
        div.enter-text(v-if="state=='start'")  
          img.mb-2.mt-4(src="/img/nchc logo 1.svg", width="150px").text-center
          h1 National Center <br>for High-performance Computing
          h5
            //- span(@click="startExplore") [ 時光隧道 ]
            router-link(to='/about') [ 關於國網 ]
            router-link(to='/videos') [ 微電影 ]
            router-link(to='/milestone') [ 時光隧道 ]
        div(v-else)
          Event(:event="currentEvent",  @click.stop="()=>{}")
      //- transition(name="fade")
      //-   // 探索模式
      //-   div(v-if="state=='explore'") 
    .navigator(v-if="state=='explore'")
      div(@click="prevClick")
        i.fas.fa-angle-up
      div(@click="nextClick")
        i.fas.fa-angle-down 
    LightRail(ref="LightRail")
    HUD(ref="HUD" :drawText="true")
    transition(name="fade" mode="out-in")
      //- 要先給一個 init 的圖片以 執行 webgl => goto() => this.particles.init()
      Particles(v-if="state=='explore'", ref="Particles" :initImageUrl="`/img/cata/blank.png`"
       :transform="{x:-150, y:-50, scale: 0.80}")  
        //:imageUrl="currentCataUrl"
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import componentExample from "@/components/componentExample.vue";
import LightRail from "@/components/LightRail.vue";
import HUD from "@/components/HUD.vue";
import Event from "@/components/Event.vue";
import Particles from "@/components/Particles.vue";
import {mapState,mapMutations} from 'vuex'

export default {
  name: "Home",
  data(){
    return {
    }
  },
  methods:{
    ...mapMutations(['setCurrentEventId','deltaCurrentEventId']),
    nextClick(){
      this.deltaCurrentEventId(1)
      this.$refs.LightRail.next()
      // this.$refs.Particles.next()
    },
    prevClick(){
      this.deltaCurrentEventId(-1)
      this.$refs.LightRail.prev()
      // this.$refs.Particles.next()
    },
    startExplore(){
      this.state='explore'
      this.$nextTick(()=>{
        this.nextClick()
      })
    }

  },
  components: {
    HelloWorld,
    componentExample,
    LightRail,
    HUD,
    Event,
    Particles
  },
  computed:{
    ...mapState(['currentEventId','chronology']),
    currentEvent(){
      let event = this.chronology.find(event => event.id == this.currentEventId)
      // console.log(event)
      // let event = this.chronology[this.currentEventId] || {}
      // console.log(this.chronology.map(e=>e.cat).filter((d,i,arr)=>arr.indexOf(d)==i))
      return event
    },
    currentCataUrl(){
      //目前資料集裡有的類別
      // 0: "組織變動"
      // 1: "重要事蹟&里程碑"
      // 2: "重要研發成果&服務"
      // 3: "重要殊榮&認證"
      // 直接對應到檔案名稱，之後維護比較方便
      return "/img/cata/"+this.currentEvent.cat+".jpg"
    },
    state(){
      if(this.$route.path.includes('explore')) return 'explore'
      return 'start'
    }
  },
  watch: {
    currentEventId(){
      this.$refs.Particles.setImage(this.currentCataUrl)
    },
    // currentEvent(){
    //   this.$refs.Particles.reload(this.currentCataUrl)
    // }
  },
  mounted(){
    let targetEventId = this.$route.params.eventId
    if(targetEventId) this.setCurrentEventId(targetEventId)
  }

};
</script>

<style lang="sass">
#canvasEl
  position: fixed
  left: 50%
  top: 50%
  z-index: 2147483647
  transform: translate3d(-50%, -50%, 0)
  pointer-events: none

.home
  color: white
  // background-color: #000000
  height: 100vh
  width: 100vw
  .enter-text
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
  // display: flex
  // justify-content: center
  // align-items: center
  .navigator
    font-size: 4rem
    position: absolute
    left: 50%
    bottom: 10%
    display: flex
    flex-direction: column
    z-index: 50
    div
      cursor: pointer
      opacity: 0.8
      &:hover
        transform: scale(1.2)

    
  .content 
    padding-left: 50px
    padding-right: 50px
  h1
    font-size: 4rem
    font-weight: 100
    margin-top: 30px
    margin-bottom: 30px
  h3
    font-weight: 900
  h5
    cursor: pointer
    letter-spacing: 3px
    *
      color: white
      margin: 0px 20px

  #Particles
    z-index: 6
</style>
