import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "@/router"
import Moment from 'moment';

Vue.use(Vuex);
// console.log(data)
export default new Vuex.Store({
  state: {
    browser: {
      name: "",
      version: ""
    },
    isBgMusicMuted: true,
    chronology: [],
    currentEventId: 9, 
    currentDate: new Date(),
    currentMilestoneYear: 2021,
    eventsByYear: {}
  },
  mutations: {
    setBrowser(state, data) {
      state.browser = data
    },
    toggleBgMusic(state, status){
      if(status!==undefined){
        state.isBgMusicMuted = status === 'off' ? true : false
      }else{
        state.isBgMusicMuted = !state.isBgMusicMuted
      }
    },
    setCurrentEventId(state,id){
      state.currentEventId = id
      //set global year for ruler rotation
      let currentEvent = state.chronology.find(event => event.id == id)
      state.currentDate = new Date(currentEvent.date || (currentEvent.year+"/1/1"))
      state.currentMilestoneYear = currentEvent.year
    },
    deltaCurrentEventId(state,delta){
      state.currentEventId = parseInt(state.currentEventId) + parseInt(delta)
      if(state.currentEventId > state.chronology.length-1) state.currentEventId = 0
      if(state.currentEventId < 0) state.currentEventId = state.chronology.length-1
      router.push({ name: 'Explore', params: { eventId: state.currentEventId } })

      let currentEvent = state.chronology.find(event => event.id == state.currentEventId)
      state.currentDate = new Date(currentEvent.date || (currentEvent.year+"/1/1"))
      state.currentMilestoneYear = currentEvent.year
      // console.log(currentEvent.year)
    },
    setCurrentMilestoneYear(state,data){
      state.currentMilestoneYear = data
    },
    setChronologyData(state, data){
      state.chronology = data.map((event,index)=> {
        event['id'] = index
        return event
      })
    },
    setEventsByYear(state, data){
      let events = {}
      data.forEach((event, index)=>{
        if(!(event.year in events)) {
          events[event.year] = []
        }
        let images = []
        let parsedDate
        if(event.images.length > 0) images = event.images.map(img => {
          return img
          })
        try {
          parsedDate = new Moment(event.date, "YYYY/MM/DD").format('MMM D')
          if (parsedDate === 'Invalid date' ) parsedDate = ''
        } catch (error) {
          parsedDate = ''
        }

        events[event.year].push({
          id: index,
          date: parsedDate,
          description: event.title,
          images: images,
          imgDirection: event.image_direction
        })
      })
      // console.log(events)
      state.eventsByYear = events
    }
  },
  actions: {
    changeCurrentMilestoneYear (context, data) {
      context.commit('setCurrentMilestoneYear', data)
    },
    updateChronologyData(context, data){
      // console.log('update chronology data')
      context.commit('setChronologyData', data)
      context.commit('setEventsByYear', data)
    }
  },
  modules: {}
});
