<template lang="pug">
  .videos(ref="videos")
    //- 影片蓋版
    .icon-instruction
      img(:src="require('@/assets/images/video/video_instruction.svg')")
    FullPage(@closeFullpage="closeVideo" :show="showVideo" :video="selectedVideo")
      .frame-wrapper-block
        video(width="1600" height="800" volume="0" ref="js-video-source" id="js-video-source" @click="handlePlayPauseVideo")
          source(:src="selectedVideoUrl")
        .icon_play_pause(ref="js-video-button-icon")
        #video-controls
          button.video-play-pause-button(ref="js-video-play-pause-button" type="button" data-state="play" @click="handlePlayPauseVideo")
          .custom-progress-block(ref="js-video-progress-wrapper" @click.self="changeVideoTime")
            .video-progress(ref="js-video-progress" :style="{width: progressbarWidth + '%'}" @click.self="changeVideoTime")
              .progress-drag-ball(:style="{left: progressbarWidth + '%'}" @mousedown.self="dragStart")
                span.video-current-time {{ currentTime }}
            .video-time {{ videoTime }}
    .inner-layer.position-relative
      .decoration-1.position-absolute
      .content.p-5(v-for = "(list,indexYear) in videoList")
        .video-list-block(v-for = "(block, indexBlock) in list" :style="{width: block.length > 1 ? '100vw': '50vw'}")
          .year(v-if="indexBlock === 0") {{years[indexYear]}}
          .block-left.animate__animated.animate__fadeInRight
            .video(@click="showVidoeDialog();selectedVideo=block[0]; ")
              Glitch(:seed="glitchSeed",
                     :width="400",
                     :height="300",
                     :size="50",
                     :count="3",
                     :static="true")
              MediaBox.preview.animate__animated.animate__zoomIn(
                :ref="`box_${indexYear}_${indexBlock}_0`"
                :class="{'is-center':`box_${indexYear}_${indexBlock}_0`===centerVideo}"
                width="750" height="450"
                :images="videoPreviews[`box_${indexYear}_${indexBlock}_0`]"
                :videos="[{url: require('@/assets/images/video/demo_video.mp4'), caption: 'none'}]"
                :showCaption="false"
                :offset="{x: 0, y: 50}"
                :scale="1.1"
                :mediaType="mediaTypes[`box_${indexYear}_${indexBlock}_0`]"
                :key="`box_${indexYear}_${indexBlock}_0`"
                )
              .p-3.mt-5.label
                h6 {{block[0]['date']}}
                h2 {{block[0]['title']}}
                p {{block[0]['brief']}}
          .block-right(v-if="block.length > 1")
            .video.upper.p-4.pr-5.animate__animated.animate__fadeInRight(
                @click="showVidoeDialog();selectedVideo=block[1]; " v-if="block[1]"
              )
              Glitch(:seed="glitchSeed",
                     :width="300",
                     :height="300",
                     :size="50",
                     :count="3",
                     :static="true")
              .p-3.label
                h6 {{block[1]['date']}}
                h2 {{block[1]['title']}}
                p {{block[1]['brief']}}
              MediaBox.preview.animate__animated.animate__zoomIn(
                :images="videoPreviews[`box_${indexYear}_${indexBlock}_1`]"
                :videos="[{url: require('@/assets/images/video/demo_video.mp4'), caption: 'none'}]"
                :showCaption="false"
                :class="{'is-center':`box_${indexYear}_${indexBlock}_1`===centerVideo}"
                width="350"
                height="300"
                :offset="{x: -10, y: 40}"
                :scale="1.2"
                :ref="`box_${indexYear}_${indexBlock}_1`"
                :mediaType="mediaTypes[`box_${indexYear}_${indexBlock}_1`]"
                )
            .video.lower.p-4.pl-5.animate__animated.animate__fadeInRight(
                @click="showVidoeDialog();selectedVideo=block[2];" v-if="block[2]"
              )
              MediaBox.preview.animate__animated.animate__zoomIn(
                :images="videoPreviews[`box_${indexYear}_${indexBlock}_2`]"
                :videos="[{url: require('@/assets/images/video/demo_video.mp4'), caption: 'none'}]"
                :showCaption="false"
                :class="{'is-center':`box_${indexYear}_${indexBlock}_2`===centerVideo}"
                width="350"
                height="300"
                :offset="{x: 15, y: 40}"
                :scale="1.2"
                :ref="`box_${indexYear}_${indexBlock}_2`"
                :mediaType="mediaTypes[`box_${indexYear}_${indexBlock}_2`]"
                )
              .p-3.label
                h6 {{block[2]['date']}}
                h2 {{block[2]['title']}}
                p {{block[2]['brief']}}
                Glitch(:seed="glitchSeed",
                       :width="300",
                      :height="300",
                      :size="50",
                     :count="3",
                      :static="true")
    HUD
</template>

<script>
import HUD from "@/components/HUD.vue";
import FullPage from "@/components/FullPage.vue";
import MediaBox from "@/components/MediaBox";
import Moment from 'moment';
import Glitch from "@/components/Glitch";
import { TweenMax, TimelineMax, gsap, Power2} from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import {mapState,mapMutations} from 'vuex'
const axios = require('axios')

export default {
  name: "Videos",
  components: { HUD, MediaBox, FullPage, Glitch },
  data() {
    return {
      hoveredBlockId: '',
      mediaTypes: {},
      glitchSeed: 0,
      years: [],
      showVideo: false,
      videos: {},
      videoProgress: null,
      progressbarWidth: 0,
      videoProgressStatus: false,
      videoTimeout: null,
      videoPromise: undefined,
      videoTime: "",
      videoCurrentTime: 0,
      videoPreviews: {},
      selectedVideo: {},
      selectedVideoUrl: '',
      centerVideo: 'box_0_0_0',
      prevCenterVideo: '',
      tl: new TimelineMax(),
      boxes: [],
      interval: null,
      timeout: null
    };
  },
  computed: {
    ...mapState(['isBgMusicMuted']),
    videoList() {
      let res = [];
      this.years.forEach(year => {
        let yearData = [];
        let vidBatch = [];
        let count = 0;

        this.videos[year].forEach((vid, index) => {
          if (count % 3 === 0 && index !== 0) {
            count = 0;
            yearData.push(vidBatch);
            vidBatch = [];
          }
          vidBatch.push(vid);
          count += 1;
        });
        yearData.push(vidBatch);
        res.push(yearData);
      });
      return res;
    },
    currentTime() {
      return this.convertVideoTimeToHHMMSS(this.videoCurrentTime) || "00:00";
    },
  },
  watch: {
    selectedVideo(){
      let baseVideoUrl = process.env.VUE_APP_STORAGE_BASE_URL
      let fileName = encodeURIComponent(this.selectedVideo.title.trim())
      let url = `${this.selectedVideo.video_link}`
      console.log(url)
      this.selectedVideoUrl = url
      this.progressbarWidth = 0;
      this.videoCurrentTime = "00:00";
    },
    centerVideo(newCenterVideo, oldCenterVideo){
      this.previewVideo(newCenterVideo, oldCenterVideo)
    }
  },
  created() {
    window.addEventListener("wheel", this.handleScroll);
    this.getVideos()
  },
  mounted(){
    gsap.registerPlugin(CSSRulePlugin);
    gsap.registerPlugin(ScrollToPlugin);

    setTimeout(()=>{
      this.boxes = Object.keys(this.$refs)
                        .filter(k=>k.includes('box'))
                        .map(boxId => {
                          try{
                            return {
                              'id': boxId,
                              'offsetLeft': this.$refs[boxId][0].$el.getElementsByClassName('VueCarousel-wrapper')[0].getBoundingClientRect().x
                            }
                          }catch(err){
                            console.log(err)
                          }
                          
                        })
                        .sort((b1,b2)=>b1.offsetLeft - b2.offsetLeft)
      setTimeout(this.startAutoScroll, 25000)
    }, 3000)
    
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.handleScroll);
    this.clearVideoProgress();
  },
  methods: {
    ...mapMutations(['toggleBgMusic']),
    getVideos(){
      axios.get(process.env.VUE_APP_API_URL + '/videos').then(res=>{
        const videos = res.data
        videos.sort((vid1, vid2)=>{
          return new Moment(vid2.date, 'YYYY-MM-DD') - new Moment(vid1.date, 'YYYY-MM-DD')
        })
        videos.forEach(vid => {
          let year = parseInt(vid.date.split("/")[0]);
          if (!this.years.includes(year)) {
            this.years.push(year);
            this.videos[year] = [];
          }
          vid.brief = vid.content.slice(0, 50) + "...";
          if(!vid.video_link){
            vid["video_link"] = process.env.VUE_APP_API_URL + vid["video"][0]["url"]
          }
          vid["thumbnail"] = process.env.VUE_APP_API_URL + vid["thumbnail"]["url"]
          this.videos[year].push(vid);
        });
        this.updateMediaTypes()
        this.previewVideo(this.centerVideo, '')
      })
    },
    startAutoScroll(){
      const vids = this.$refs["videos"]
      const boxes = this.boxes
      this.interval = setInterval(()=>{
        // 找到前ㄧ個置中影片
        let index = boxes.map(box=>box.id).findIndex(id => this.centerVideo === id)

        // 如果前ㄧ個是最後一則的話，就用 -1 當前ㄧ個
        if(index === boxes.length-1) index = -1

        // 更新當前中間影片
        this.centerVideo =  boxes[index+1].id
        TweenMax.to(vids, 3 ,{
          scrollTo:{
              x: boxes[index+1].offsetLeft - 650,
              autoKill: true
            },
            ease: Power2.easeInOut
        })
      }, 8000)
    },
    previewVideo(currentVideo, prevVideo){
      const tl = this.tl 
      const rule = CSSRulePlugin.getRule(".videos .video .preview.is-center:before")
      if (!this.$refs[currentVideo]) return
      const elImg = this.$refs[currentVideo][0].$el.getElementsByClassName('slide-image')[0]

      if(tl.isActive()){
        tl.pause(0)
        tl.clear()
        tl.restart()
      }

      if(prevVideo){
        const oldElImg =this.$refs[prevVideo][0].$el.getElementsByClassName('slide-image')[0]
        // 重置前一張圖片
        tl.set(oldElImg,{
          scale: 1,
          filter: 'brightness(1)'
        })
      }

      // 背景亮度
      tl.fromTo(elImg, {
        filter: 'brightness(1)'
      },{
        scale: 1.3,
        filter: 'brightness(0.5)',
        ease: Power2.easeOut,
        duration: 8
        }
      )

      // play icon
      tl.fromTo(rule,
        {
          cssRule:{
            opacity: 0
          }
        },{
          cssRule:{
            opacity: 1
          },
          duration: 1,
          delay: 0
        },0
      )
    },
    updateMediaTypes(){
      let mediaTypes = {}
      this.years.forEach((year,yearIndex) => {
        let count = 0;
        let block = 0
        this.videos[year].forEach((vid, index) => {
          block = Math.floor(index/3)
          if (count % 3 === 0 && index !== 0) {
            count = 0;
          }
          let boxId = `box_${yearIndex}_${block}_${count}`
          mediaTypes[boxId] = 'image';
          // 更新預覽圖
          this.$set(this.videoPreviews, boxId, [
            {
            url: vid.thumbnail,
            caption: "none"
          }])
          count += 1;
        });
        this.mediaTypes = mediaTypes
      });
    },
    setPlayIcon(state){
      const refVideoIcon = this.$refs["js-video-button-icon"];
      refVideoIcon.classList.remove('trigger-play')
      refVideoIcon.classList.remove('icon-play')
      refVideoIcon.classList.remove('icon-pause')

      setTimeout(()=>{
        refVideoIcon.classList.add('trigger-play')
        if(state === 'play'){
          refVideoIcon.classList.add('icon-pause')
        }else{
          refVideoIcon.classList.add('icon-play')
        }
      },100)
    },
    updateBoxPosition(updateCenterVid){
      updateCenterVid = updateCenterVid === false ? false : true
      let screenMiddle = document.getElementById('app').scrollLeft + window.innerWidth/2
      const boxes = Object.keys(this.$refs)
                        .filter(k=>k.includes('box'))
                        .map(boxId => {
                          // console.log(boxId)
                          return {
                            'id': boxId,
                            'offsetLeft': this.$refs[boxId][0].$el.getElementsByClassName('VueCarousel-wrapper')[0].getBoundingClientRect().x
                          }
                        })
                        .sort((b1,b2)=>b1.offsetLeft - b2.offsetLeft)

      // 取得預覽圖片距離畫面左側的位置
      let leftVideos = []

      boxes.forEach((box,index)=>{
        if(screenMiddle - 300 > box.offsetLeft){
          leftVideos.push(box.id)
        }
      })
      if(this.centerVideo !== leftVideos[leftVideos.length-1]){
        this.centerVideo = leftVideos[leftVideos.length-1]
      }
    },
    handleScroll(evt) {
      if(this.interval){
        clearInterval(this.interval)
      }

      if(this.timeout){clearTimeout(this.timeout)}
      this.timeout = setTimeout(this.startAutoScroll, 30000)

      if(!this.showVideo){
        this.$refs.videos.scrollLeft += evt.deltaY;
        this.glitchSeed = this.$refs.videos.scrollLeft
      }
      
      this.updateBoxPosition()

    },
    getThumbnails(title, type) {
      type ? type : 'jpg'
      try {
        return [
          {
            url: require(`@/assets/images/video_thumbnails/${title}.jpg`),
            caption: "none"
          }
        ];
      } catch (error) {
        return [
          { url: require(`@/assets/images/video-2.png`), caption: "none" }
        ];
      }
    },
    showVidoeDialog() {
      this.showVideo = true;
      this.progressbarWidth = 0;
      this.videoCurrentTime = 0;
      this.$nextTick(() => {
        const refVideo = this.$refs["js-video-source"];
        refVideo.addEventListener("loadedmetadata", () => {
          this.videoTime = this.convertVideoTimeToHHMMSS(refVideo.duration);
        });
        this.handlePlayPauseVideo()
      });
    },
    convertVideoTimeToHHMMSS(second) {
      const s = parseInt(second, 10);
      const hours = Math.floor(s / 3600);
      const minutes = Math.floor(s / 60) % 60;
      const seconds = s % 60;

      return [hours, minutes, seconds]
        .map(v => (v + "").padStart(2, "0"))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    },
    handlePlayPauseVideo() {
      this.toggleBgMusic('off')
      const refVideo = this.$refs["js-video-source"];
      const refVideoBtn = this.$refs["js-video-play-pause-button"];

      if (refVideo.paused) {
        this.videoPromise = refVideo.play();
        this.setPlayIcon('pause')
        this.videoProgress = setInterval(() => {
          this.videoCurrentTime = refVideo.currentTime;
          this.progressbarWidth =
            (refVideo.currentTime / refVideo.duration) * 100;
          if (this.progressbarWidth === 100) {
            this.clearVideoProgress();
            this.videoTimeout = setTimeout(() => {
              // Fix: Uncaught (in promise) DOMException: The play() request was interrupted by a call to pause().
              if (this.videoPromise !== undefined) {
                this.videoPromise.then(_ => {
                  this.videoCurrentTime = refVideo.currentTime = 0;
                  refVideo.pause();
                  refVideoBtn.classList.remove("pause");
                });
              }
              refVideoBtn.classList.remove("pause");
              this.progressbarWidth = 0;
              this.videoCurrentTime = 0;
            }, 400);
          }
        }, 400);

        refVideoBtn.classList.add("pause");
        return;
      }
      this.setPlayIcon('play')
      this.clearVideoProgress();
      if (this.videoPromise !== undefined) {
        this.videoPromise.then(_ => {
          refVideo.pause();
          refVideoBtn.classList.remove("pause");
        });
      }
    },
    clearVideoProgress() {
      if (this.videoProgress !== null) {
        clearInterval(this.videoProgress);
      }
      if (this.videoTimeout !== null) {
        clearTimeout(this.videoTimeout);
      }
    },
    changeVideoTime() {
      const refVideo = this.$refs["js-video-source"];
      const refVideoBtn = this.$refs["js-video-play-pause-button"];
      if (refVideoBtn.classList.contains("pause")) {
        refVideoBtn.classList.remove("pause");
      }
      const { width, left } = this.$refs[
        "js-video-progress-wrapper"
      ].getBoundingClientRect();
      this.progressbarWidth = ((event.clientX - left) / width) * 100;
      this.videoCurrentTime = refVideo.currentTime =
        (this.progressbarWidth / 100) * refVideo.duration;
      this.clearVideoProgress();
      // Fix: Uncaught (in promise) DOMException: The play() request was interrupted by a call to pause().
      if (this.videoPromise !== undefined) {
        this.videoPromise.then(_ => {
          refVideo.pause();
          refVideoBtn.classList.remove("pause");
          this.handlePlayPauseVideo();
        });
      }
    },
    dragStart() {
      const refVideo = this.$refs["js-video-source"];
      const refVideoBtn = this.$refs["js-video-play-pause-button"];
      this.videoProgressStatus = true;
      // Fix: Uncaught (in promise) DOMException: The play() request was interrupted by a call to pause().
      if (this.videoPromise !== undefined) {
        this.videoPromise.then(_ => {
          refVideo.pause();
          refVideoBtn.classList.remove("pause");
        });
      }
      this.clearVideoProgress();
      if (refVideoBtn.classList.contains("pause")) {
        refVideoBtn.classList.remove("pause");
      }
      const el = document.documentElement;
      el.addEventListener("mousemove", this.dragMove, false);
      el.addEventListener("mouseup", this.dragStop, false);
    },
    dragMove() {
      if (this.videoProgressStatus) {
        const refVideo = this.$refs["js-video-source"];
        const { width, left } = this.$refs[
          "js-video-progress-wrapper"
        ].getBoundingClientRect();
        this.progressbarWidth = ((event.clientX - left) / width) * 100;
        this.progressbarWidth =
          this.progressbarWidth < 0
            ? 0
            : this.progressbarWidth > 100
            ? 100
            : this.progressbarWidth;
        this.videoCurrentTime =
          (this.progressbarWidth / 100) * refVideo.duration;
      }
    },
    dragStop() {
      const refVideo = this.$refs["js-video-source"];
      this.videoProgressStatus = false;
      this.videoCurrentTime = refVideo.currentTime =
        (this.progressbarWidth / 100) * refVideo.duration;
      this.clearVideoProgress();
      this.handlePlayPauseVideo();
      const el = document.documentElement;
      el.removeEventListener("mousemove", this.dragMove, false);
      el.removeEventListener("mouseup", this.dragStop, false);
    },
    closeVideo() {
      this.showVideo = false;
      this.clearVideoProgress() 
    }
  }
};
</script>

<style lang="sass">
.frame-wrapper-block
  position: relative
  margin-bottom: 48px
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  &:before
    content: ""
    width: 94%
    height: 92%
    position: absolute
    left: 0%
    top: 2%
    border: 1px solid #ffffff
    z-index: -1
    background: linear-gradient(to top left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 2px), rgb(255, 255, 255) 50%, rgba(0, 0, 0, 0) calc(50% + 2px), rgba(0, 0, 0, 0) 100%), linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 2px), rgb(255, 255, 255) 50%, rgba(0, 0, 0, 0) calc(50% + 2px), rgba(0, 0, 0, 0) 100%)
.videos
  .VueCarousel .VueCarousel-wrapper .VueCarousel-slide div
    background-color: transparent
.videos
  width: 100vw
  height: 100vh
  background-color: black
  overflow: auto hidden
  .icon-instruction
    position: absolute
    left: 30vw
    width: 8vw
    top: 7vh
  .glitch-container
    z-index: 50 !important
    left: initial !important
    top: initial !important
    right: 0 !important
    bottom: 0 !important
  .fullpage
    .frame-wrapper-block
      video
        cursor: pointer
        position: relative
      .icon_play_pause
        position: absolute
        left: calc( 50% - 5vw )
        top: 45%
        width: 5vw
        height: 5vw
        pointer-events: none
        background-position: center center
        &.icon-play
          background-image: url("../assets/images/icons/video-play-button.svg")
        &.icon-pause
          background-image: url("../assets/images/icons/video-pause-button.svg")
        &.trigger-play
          animation: fadeout 1s linear forwards

  .video
    cursor: pointer
    .preview
      transition: 0.5s
  .video .preview.is-center:before
    content: ''
    background: url("../assets/images/icons/video-play-button.svg") no-repeat 50% 50%
    background-size: 30%
    width: 100%
    height: 100%
    position: absolute
    left: 50%
    top: 50%
    transform: translate3d(-55%, -45%, 0)
    z-index: 10
    opacity: 0
    transition: 5s



  #video-controls
    box-sizing: border-box
    width: 1564px
    margin-top: 18px
    margin-left: 18px
    display: flex
    align-items: center
    .video-play-pause-button
      width: 24px
      height: 24px
      background-color: inherit
      background-image: url("../assets/images/video/play_button.png")
      background-size: contain
      border: none
      outline: none
      &.pause
        background-image: url("../assets/images/video/pause_button.png")
      &:hover
        transform: scale(1.05)
    .custom-progress-block
      flex: 1
      margin-left: 16px
      height: 5px
      background-color: rgba(#ffffff, 0.3)
      position: relative
      cursor: pointer
      .video-progress
        height: 5px
        background-color: #ffffff
      .progress-drag-ball
        position: absolute
        display: block
        width: 18px
        height: 18px
        border-radius: 50%
        background-color: #ffffff
        top: 50%
        transform: translate(-12px, -50%)
        &:hover
          transform: translate(-12px, -50%) scale(1.1)
      .video-current-time
        position: absolute
        bottom: -28px
        left: 50%
        transform: translateX(-50%)
        font-size: 14px
      .video-time
        position: absolute
        right: 0
        bottom: -24px
        font-size: 14px
        color: #FFFFFF
        opacity: 0.56

  .inner-layer
    display: flex
    align-items: initial
    justify-content: flex-start
    position: relative
    z-index: 50
    transition: 1s


  .content
    z-index: 10
    // min-width: 100vw
    // width: 100vw
    display: flex
    align-items: center
    justify-content: center
    flex-direction: row
    // overflow: scroll
    position: relative
    .video-list-block
      // 如果 block 的長度只有一個的話，在 :style 內會把寬度調整為 50vw
      width: 120vw
      height: 100%
      display: flex
      align-items: center
      justify-content: center
      flex-direction: row
    .year
      position: absolute
      top: 10%
      left: 0%
      font-size: 128px
      color: black
      font-weight: bold
      z-index: 10
      transform: translate(-5px, 0)
      -webkit-text-fill-color: transparent
      -webkit-text-stroke-width: 1px
      -webkit-text-stroke-color: white
    &>div
      // border: white 1px solid
      min-height: 200px
      text-align: left
      color: white
      h6,.tags
        display: inline-block
        font-weight: light
    .block-left
      flex: 4
      height: 100%
      margin-right: 50px
      .box
        width: 100%
    .block-right
      flex: 6
      height: 100%
      margin-right: 50px
      &>div
        display: flex
        align-items: center
        justify-content: center
        height: 50%
        &>div:not(.box)
          width: 50%
          z-index: 2
        .box
          z-index: 1

@keyframes fadeout 
  0%
    transform: scale(1)
    opacity: 1
  100%
    transform: scale(2)
    opacity: 0
</style>
