<template lang="pug">
.text-box.p-3(:class="{ 'pr-5': canClose }")
  .text-close(@click="$emit('close')", v-if="canClose") X
  h5.mt-2(v-if="showTitle") {{title}}
  p.mt-4(v-html="content")
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'EXTENDED CONTENT'
    },
    content:{
      type: String,
      default: `第一屆尋找資安女婕思啟動記者會合照:(後排左起) IBM全球資訊安全部門謝明君協理、SPLUNK李淑銘總經理、教育部資科司劉文惠副司長、中小企業信用保證基金許婉琪董事長、科技部吳政忠部長、中央研究院李德財院士、國安會資通安全辦公室尤暖霞主任、臺灣大學電機系葉丙成教授、科技報橘莊惠婷執行長、(前排)臺北市立第一女子高級中學及臺北市立松山高級中學同學。
                    <br><br>第一屆尋找資安女婕思啟動記者會合照:(後排左起) IBM全球資訊安全部門謝明君協理、SPLUNK李淑銘總經理、教育部資科司劉文惠副司長、中小企業信用保證基金許婉琪董事長、科技部吳政忠部長、中央研究院李德財院士、國安會資通安全辦公室尤暖霞主任、臺灣大學電機系葉丙成教授、科技報橘莊惠婷執行長、(前排)臺北市立第一女子高級中學及臺北市立松山高級中學同學。
                    <br><br>第一屆尋找資安女婕思啟動記者會合照:(後排左起) IBM全球資訊安全部門謝明君協理、SPLUNK李淑銘總經理、教育部資科司劉文惠副司長、中小企業信用保證基金許婉琪董事長、科技部吳政忠部長、中央研究院李德財院士、國安會資通安全辦公室尤暖霞主任、臺灣大學電機系葉丙成教授、科技報橘莊惠婷執行長、(前排)臺北市立第一女子高級中學及臺北市立松山高級中學同學。`
    },
    showTitle: {
      default: true
    },
    canClose: {
      default: true
    }
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="sass">
.text-box
    pointer-events: auto
    border: solid 1px white
    position: relative
    height: fit-content
    max-width: 30vw
    text-align: left
    background-color: black
    max-height: 50vh
    overflow: auto scroll
    top: 0
    h5
        position: relative
        margin-left: 25px
        &:before
            content: ''
            width: 10px
            height: 10px
            background-color: red
            border-radius: 50%
            position: absolute
            left: -20px
            top: 7.5px
    .text-close
        position: sticky
        position: -webkit-sticky
        top: 0px
        transform: translateX(30px)
        width: fit-content
        float: right
        color: white
        font-weight: bold
        font-size: 2rem
        &:hover
            cursor: pointer
</style>